<div class="notification__container">
  <app-side-nav-bar></app-side-nav-bar>
  <div class="notification__wrapper">
    <div class="notification__wrapperSection">
    <div class="notification__headerContainer">
      <div class="notification__header">
        <div class="notification__headerTitle">Notification</div>
        <div class="notification__headertitleDescription">
          Manage all your alerts and notifications here
        </div>
      </div>
      <div class="notification__operations">
        <div class="notification__operation-read">Mark All As Read</div>
        <div class="notification__filterButton">
          <img (click)="op.toggle($event)"
            src="../../../assets/Images/user-management/user-Filter.svg"
            alt="filter"
          />
          <p-overlayPanel #op  >
            <ng-template pTemplate="content">
              <div class="notification__hover">
                <div class="notification__options">
                    <div class="notification__option">
                        <div class="notification__optionText">All Notifications</div>
                    </div>
                </div>
                <div class="notification__options">
                    <div class="notification__option">
                        <div class="notification__optionsText">Packages</div>
                    </div>
                </div>
                <div class="notification__options">
                    <div class="notification__option">
                        <div class="notification__optionsText">Network</div>
                    </div>
                </div>
                <div class="notification__options">
                    <div class="notification__option">
                        <div class="notification__optionsText">Users</div>
                    </div>
                </div>
                <div class="notification__options">
                    <div class="notification__option">
                        <div class="notification__optionsText">Organisation</div>
                    </div>
                </div>
                <div class="notification__othersWrapper">
                    <div class="notification__option">
                        <div class="notification__othersText">Others</div>
                    </div>
                </div>
            </div>
            </ng-template>
        </p-overlayPanel>
        </div>
        <div class="notification__searchButton">
          <img
            src="../../../assets/Images/notification/Sort.svg"
            alt="search"
          />
        </div>
      </div>
    </div>
    <div class="notification__cardswrapper">
      <ng-container *ngFor="let data of NotificationData">
        <app-notification-card [dailyData]="data"></app-notification-card>
      </ng-container>
    </div>
    </div>
    <div class="notification__paginator">
      <p-paginator [first]="1" [rows]="10" [totalRecords]="120" [showFirstLastIcon]="false" showEllipsis="true" styleClass="paginator"></p-paginator>
    </div>
  </div>
</div>
