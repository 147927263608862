import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { PackageService } from '../../services/package-service/package.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  @Output() passFormValue = new EventEmitter<any>();
  documentTypes           : any = [];
  formValues              : any;
  filterForm              !: FormGroup;
  contactList             : Array<any> = [];
  
  faSearch = faSearch;

  constructor(
    private fb              : FormBuilder,
    private packageService  : PackageService
  ) { }

  ngOnInit(): void {
    this.initializeFilterForm();
    this.getAllDocumentTypes();

    this.getAllContacts();

    this.filterForm.valueChanges.subscribe(() => {
      this.formValues = this.filterForm.value;
      this.passFormValue.emit(this.filterForm.value);
    });
  }

  initializeFilterForm() {
    this.filterForm = this.fb.group({
      startdate: [''],
      enddate: [''],
      documentName: [''],
      documentType: [''],
      selectedContact: [''],
    });
  }

  resetForm() {
    this.filterForm.reset();
    this.formValues = null;
  }

  getAllDocumentTypes() {
    this.packageService.getListDocumentTypes(false).subscribe((res) => {
      let _types = [];
      for (let docType of res.data) {
        for (let document of docType.documents) {
          _types.push({ ...document });
        }
      }
      this.documentTypes = _types;
    });
  }
  

  getAllContacts() {
    this.packageService.getAllContacts({ active: true }).subscribe((res) => {
      this.contactList = res.data;
    })
  }
}
