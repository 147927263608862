import { Injectable } from '@angular/core';
import { HttpDataService } from '../httpService/http-data.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class PackageService {
  FilterSubject = new BehaviorSubject({});
  constructor(
    private httpDataService: HttpDataService,
    private httpClient: HttpClient
  ) {}

  getListDocumentTypes(groupByCategory: boolean): Observable<any> {
    let params: any = {};
    if (groupByCategory) {
      params.groupByCategory = true;
    }
    return this.httpClient.get(`${environment.API_URL}/v1/document-type`, {
      params,
    });
  }

  getAllIncomingPackages(params?: any): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/package/inbox`, {
      params,
    });
  }
  getAllContactsForSelectedCompanyPackages(companyId: any): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/contacts/users/${companyId}`
    );
  }

  getAllOutgoingPackages(params?: any): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/package/outbox`, {
      params,
    });
  }

  uploadFileForPackage(payload: any): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/v1/digilocker/private-file/upload`,
      payload
    );
  }

  getFileForPackage(documentId: any): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/`+documentId);
  }

  getAllContacts(params?: any): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/contacts`, {
      params,
    });
  }

  createPackage(payload: any): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/v1/package/send`,
      payload
    );
  }

  getPackageById(packageId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/package/${packageId}/log`
    );
  }

  getPackageStatus(packageId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/package/${packageId}/status`
    );
  }

  getPackageLog(packageId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/package-log/${packageId}`
    );
  }

  getPackageDocumentLog(
    packageId: string,
    documentId: string
  ): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/package-log/${packageId}/${documentId}?limit=50`
    );
  }

  exportPackageDocument(
    documentId: string,
    notCurrentOwner: boolean
  ): Observable<any> {
    let endpoint = notCurrentOwner ? 'export-watermark' : 'export';
    return this.httpClient.post(
      `${environment.API_URL}/v1/digilocker/document/${documentId}/${endpoint}`,
      {},
      { responseType: 'blob', observe: 'response' }
    );
  }

  downloadFile(doc: any, returnObs: any) {
    let headers: HttpHeaders = new HttpHeaders();
     headers.append('downloadFile', 'true');

    let obs: any = this.httpClient.post(`${environment.API_URL}/v1/digilocker/document/${doc}/export`, {},
      { responseType: 'blob', observe: 'response' })
    if (returnObs) {
      return obs;
    } else {
      obs.subscribe((response: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.body);
        link.download = response.headers.get('file-name');
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
      })
    }
  }

  exportPackage(packageId: string): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/v1/package/${packageId}/export`,
      {},
      { responseType: 'blob', observe: 'response' }
    );
  }

  savePackageDraft(packageDetails: any): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/v1/package-draft`,
      packageDetails
    );
  }
  verifyBlockChain(packageDetails: any): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/v1/blockchain-key/verify`,
      packageDetails
    );
  }

  getDraftPackages(params?: any): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/package-draft`, {
      params,
    });
  }
  getRequestPackages(params?: any): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/package-draft/requests`,
      {
        params,
      }
    );
  }

  updateDraftPackage(packageDetails: any, packageId: string): Observable<any> {
    return this.httpClient.put(
      `${environment.API_URL}/v1/package-draft/${packageId}`,
      packageDetails
    );
  }

  rejectPackage(packageDetails: any, packageId: string): Observable<any> {
    return this.httpClient.put(
      `${environment.API_URL}/v1/package-draft/${packageId}/reject`,
      packageDetails
    );
  }

  discardDraftPackage(packageId: string, payload?: any): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/v1/package-draft/${packageId}/discard`,
      payload
    );
  }

  getDraftById(draftId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/package-draft/${draftId}`
    );
  }
  getPackageMetricsCount(params: any, packageType: any): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/package-dashboard/count`, { params: {...params, countType:packageType}}
    );
  }

  private selectedPacageType: BehaviorSubject<any> = new BehaviorSubject(
    'Received'
  );

  public getSelectedPacageType(): Observable<any> {
    return this.selectedPacageType.asObservable();
  }

  public setSelectedPacageType(selectedPackage: any) {
    this.selectedPacageType.next(selectedPackage);
  }

  private blockChainTransfer: Subject<any> = new Subject<any>();
  public getblockChainTransferDetails(): Observable<any> {
    return this.blockChainTransfer.asObservable();
  }
  public getFilterSubject(): Observable<any> {
    return this.FilterSubject.asObservable();
  }

  public setblockChainTransferDetails(selectedPackage: any) {
    this.blockChainTransfer.next(selectedPackage);
  }
  getAllPreferences(): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/v1/preferences/getAllPreferences`
    );
  }

  updatePreferences(preferenceDetails: any): Observable<any> {
    return this.httpClient.put(
      `${environment.API_URL}/v1/preferences/updatePreference`,
      preferenceDetails
    );
  }
}
