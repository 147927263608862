import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SetupAccountService {
  constructor(private http: HttpClient) {}

  generateOtp(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/company/email-otp';
    return this.http.post(url, payload);
  }

  verifyOtpStep(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/company/create';
    return this.http.post(url, payload);
  }

  secondryUserAccountSetup(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/generate-setup-otp';
    return this.http.post(url, payload);
  }

  registerSecondryUser(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/setup';
    return this.http.post(url, payload);
  }
}
