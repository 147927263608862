<div class="accountLogin__sectionWrapper">

    <div class="accountLogin__userWrapper">
        <img src="../../../assets/Images/login/Users.svg" alt="Users">
        <div class="accountLogin__userText">User Access Control</div>
    </div>

    <div class="accountLogin__sdexLogo">
        <img class="accontLogin__logoImg" src="../../../assets/Images/login/logo.svg" alt="logo">
    </div>

    <div class="accountLogin__PersonalizationWrapper">
        <img src="../../../assets/Images/login/settings.svg" alt="settings">
        <div class="accountLogin__userText">Personalization</div>
    </div>

    <div class="accountLogin__DataWrapper">
        <img src="../../../assets/Images/login/securityData.svg" alt="securityData">
        <div class="accountLogin__userText">Data Security</div>
    </div>

    <div class="accountLogin__workWrapper">
        <img src="../../../assets/Images/login/dataWorkflow.svg" alt="dataWorkflow">
        <div class="accountLogin__userText">Seamless Workflows</div>
    </div>

    <div class="accountLogin__transfersWrapper">
        <img src="../../../assets/Images/login/transfers.svg" alt="transfers">
        <div class="accountLogin__userText">Faster Transfers</div>
    </div>

</div>