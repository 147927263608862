<div class="zoom-slider">
  <div class="zoom-slider-btn" (click)="decreaseZoom()">
    <img src="../../../assets/Images/toggle-btn/Minimize.svg" alt="min" />
  </div>
  <input
    type="range"
    min="1"
    max="10"
    class="custom-slide"
    [(ngModel)]="zoomLevel"
    (ngModelChange)="onValueChange($event)"
  />
  <div class="zoom-slider-btn" (click)="increaseZoom()">
    <img src="../../../assets/Images/toggle-btn/Add.svg" alt="add" />
  </div>
</div>
