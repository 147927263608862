import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(public storageService: StorageService, public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.storageService.getDataFromLocalStorage(
      StorageKeys.ACCESS_TOKEN
    );
    if (!token) {
      this.router.navigate(['login']);
    }
    return true;
  }
}
