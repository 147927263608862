<div class="user-management-bulk-update__users-main">
  <div class="user-management-bulk-update__users-wrapper">
    <div class="user-management-bulk-update__users-head">
      <div class="user-management-bulk-update__users-title">
        Bulk Upload Users
      </div>
      <div
        class="user-management-bulk-update__users-close-image"
        (click)="onCloseIconClick()"
      >
        <img
          src="../../../../assets/Images/user-management/Cross.svg"
          alt="close"
        />
      </div>
    </div>
    <div class="user-management-bulk-update__bottom-line"></div>
    <div class="user-management-bulk-update__upload">
      <div class="user-management-bulk-update__download">
        <div class="user-management-bulk-update__download-description">
          Download the User Bulk Upload template here
        </div>
        <app-common-button
          buttonClass="regularButton"
          buttonLabel="Download Template"
        ></app-common-button>
      </div>
      <div class="user-management-bulk-update__download-container">
        <div class="user-management-bulk-update__download-containerWrapper">
          <circle-progress
            [space]="-7"
            [percent]="90"
            title="90%"
            [radius]="65"
            [outerStrokeWidth]="7"
            [innerStrokeWidth]="7"
            [outerStrokeColor]="'#DE7141'"
            [innerStrokeColor]="'rgba(222, 113, 65, 0.1)'"
            [animation]="true"
            [animationDuration]="300"
            [showTitle]="true"
            [showSubtitle]="false"
            [showUnits]="false"
          ></circle-progress>
          <div class="user-management-bulk-update__file-upload-loading-text">
            File Uploading
          </div>
          <div class="user-management-bulk-update__download-cancel-button">
            Cancel
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="user-management-bulk-update__users-action-buttons">
    <app-common-button
      buttonClass="smallbutton"
      buttonLabel="Cancel"
      (click)="onCloseIconClick()"
    ></app-common-button>
    <app-common-button
      buttonClass="smallFilledButton"
      buttonLabel="Import"
    ></app-common-button>
  </div>
</div>
