import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesService } from 'src/app/share/services/roles-service/roles.service';
import { SdexAdminService } from 'src/app/share/services/sdex/sdex-admin.service';

@Component({
  selector: 'app-accept-reject-confirmation-modal',
  templateUrl: './accept-reject-confirmation-modal.component.html'
})
export class AcceptRejectConfirmationModalComponent {
  close(close: any) {
    throw new Error('Method not implemented.');
  }
  @Input() acceptRejectData       !: 'Accept' | 'Reject';
  @Input() invitationId            : any;
  @Input() companyActivated        : any;
  @Input() companyActivatedData    :any;
  @Input() message                 : string;
  @Input() roleId                  : any;
  @Input() userId                  : any;
  errorMsg                         : any;
  roleErrorMsg                     : any;
  isToast                          : boolean


  constructor(
    public activeModal    : NgbActiveModal,
    private _service      : SdexAdminService,
    private roleService   : RolesService,
    private sdexService   : SdexAdminService,
  ) { }

  closeDialog() {
    this.activeModal.close();
  }

  confirmation(type: 'Accept' | 'Reject' | 'isActivated') {
    if (type === 'Accept') {
      this._service
        .acceptInvitation(this.invitationId)
        .subscribe((res: any) => {
          this.activeModal.close({
            status: true,
            message: 'company list Accepted',
          });
        });
    } else if (type === 'Reject') {
      this._service
        .rejectInvitation(this.invitationId)
        .subscribe((res: any) => {
          this.activeModal.close({
            status: true,
            message: 'company list rejected',
          });
        });
    } else {
      this.activeModal.close({
        status: true,
        message: 'company list Activated',
      });
    }
  }
  
  deleteRole() {
    this.isToast = true
    this.roleService.deleteRole(this.roleId).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.activeModal.close({ status: true });
        }
      },
      error: (error: any) => {
        this.errorMsg = error?.error?.message;
        this.roleErrorMsg = error?.error?.message;
      },
    });
  }

  deleteUser() {
    this.sdexService.deleteSubCompanyUser(this.userId).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.activeModal.close({ status: true });
        }
      },
      error: (error: any) => {
        this.errorMsg = error?.error?.message;
      },
    });
  }

  closePopup(){
    this.activeModal.close({ status: true });
  }
}
