<div class="side-nav-bar__body">
  <div class="side-nav-bar__bodySection">
    <div class="side-nav-bar__logoWrapper" [ngClass]="{ 'side-nav-bar__logoBody': hideText }">
      <div class="side-nav-bar__logo">
        <div class="side-nav-bar__logoSection">
          <img src="../../../assets/Images/side-nav-bar/sdx__logo.svg" alt="sdex"/>
          <img [hidden]="hideText" class="side-nav-bar__icon"
            src="../../../../assets/Images/side-nav-bar/sdex__logo.svg" alt="sdex"/>
        </div>
        </div>
    </div>
    <div class="side-nav-bar__content">
      <div *ngIf=" userDetailsData?.enrollmentType!=='Admin'" class="side-nav-bar__contents pb-3">
        <div [hidden]="hideText" *ngIf="user?.assignedTo?.length > 1">
          <select class="select org-select-dropdown" [(ngModel)]="selectedCompany" (change)="onCompanyChange($event)">
            <option *ngFor="let company of user.assignedTo" [value]="company.company._id">
              {{ company.company.name }}
            </option>
          </select>
        </div>

        <div *ngIf=" userDetailsData?.enrollmentType!=='Admin'" class="side-nav-bar__dashboard"
          [routerLink]="'/admin/dashboard'"  (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/dashboard"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Dashboard':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Dashboard.svg" alt="Dashboard"/>
          <div [hidden]="hideText" class="side-nav-bar__dashboardText">
            Dashboard
          </div>
        </div>

        <div appAccessControl allowedRoles="package.view"  class="side-nav-bar__dashboard"
          routerLink="/packages/incoming" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/packages/incoming"></div>
          <div routerLinkActive="side-nav-bar__active" routerLink="/packages/create-new-package"></div>

          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Packages':''" [placement]="'right'"
            container="body" class="side-nav-bar__images" triggers="mouseenter"
            src="../../../assets/Images/side-nav-bar/Packages.svg" alt="Packages"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Packages
          </div>
        </div>
        <div appAccessControl allowedRoles="document.view" *ngIf="!authService.isAdmin()"
          class="side-nav-bar__dashboard" [routerLink]="'document-vault'"  (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="document-vault"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? ' Document Vault':''" [placement]="'right'"
            container="body" triggers="mouseenter" class="side-nav-bar__images"
            src="../../../assets/Images/side-nav-bar/DocumentVoult.svg" alt="DocumentVoult"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Document Vault
          </div>
        </div>
        <div appAccessControl allowedRoles="company-contacts.enabled" *ngIf="userDetailsData?.company === userDetailsData?.rootCompany" class="side-nav-bar__dashboard" [routerLink]="'/admin/contact-list'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/contact-list"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Network':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Network.svg" alt="Network"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Network
          </div>
        </div>
       </div>
      <div class="side-nav-bar__options">
        <div appAccessControl allowedRoles="user.view" class="side-nav-bar__dashboard"
          [routerLink]="['admin/sub-company-users']" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/sub-company-users"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Users':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Users.svg" alt="Users"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">Users</div>
        </div>
        <div class="side-nav-bar__dashboard" *ngIf="!rolesDisabled" appAccessControl allowedRoles="role.view"
          [routerLink]="'/roles/role-list'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/roles/role-list"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Roles':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Roles.svg" alt="Roles"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">Roles</div>
        </div>
        <div *ngIf="!authService.isAdmin()" #container class="side-nav-bar__dashboard" [routerLink]="'/organization-structure'"  (click)="hideListText()"
          appAccessControl allowedRoles="company.view">
          <div routerLinkActive="side-nav-bar__active" routerLink="organization-structure"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Org. Structure':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/OrgStructure.svg" alt="OrgStructure"/>
          <div  (click)="scrollTo()" [hidden]="hideText" class="side-nav-bar__contentText">
            Org. Structure
          </div>
        </div>
        <div *ngIf="authService.isAdmin()" class="side-nav-bar__dashboard" appAccessControl allowedRoles="company.view"
          [routerLink]="'/admin/sdex/company'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/sdex/company"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Organisations':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/OrgStructure.svg" alt="OrgStructure"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Organisations
          </div>
        </div>
        <div appAccessControl allowedRoles="lr.assign"
          *ngIf="authService.isAdmin() &&  userDetailsData?.enrollmentType!=='Bank'" class="side-nav-bar__dashboard"
          [routerLink]="'/admin/admin-templates-list'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/admin-templates-list"></div>
          <div routerLinkActive="side-nav-bar__active" routerLink="/lr-generation/lr-settings"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'LR Templates':''" [placement]="'right'"
            container="body" triggers="mouseenter" class="side-nav-bar__images img-lr"
            src="../../../assets/Images/side-nav-bar/lr-templates.svg" alt="lr-templates"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            LR Templates
          </div>
        </div>
      </div>

      <div class="side-nav-bar__options">
        <div appAccessControl allowedRoles="lr.view"
          *ngIf="(!authService.isAdmin() &&  userDetailsData?.enrollmentType!=='Bank') && getEnrollmentType !=='Bank'" class="side-nav-bar__dashboard"
          [routerLink]="'/admin/company-templates-list'"  (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/company-templates-list"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'LR Templates':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images img-lr"
            src="../../../assets/Images/side-nav-bar/lr-templates.svg" alt="lr-templates"/>
          <div (click)="scrollTo()" [hidden]="hideText" class="side-nav-bar__contentText">
            LR Templates
          </div>
        </div>

        <div appAccessControl allowedRoles="lr.view"
          *ngIf="(!authService.isAdmin() &&  userDetailsData?.enrollmentType!=='Bank') && getEnrollmentType !=='Bank'" class="side-nav-bar__dashboard"
          [routerLink]="'/lr-generation/generated-lrs'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/lr-generation/generated-lrs"></div>
          <div routerLinkActive="side-nav-bar__active" routerLink="/lr-generation"></div>

          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'LR List':''" [placement]="'right'"
            triggers="mouseenter" container="body"
            class="side-nav-bar__images img-lr" src="../../../assets/Images/side-nav-bar/lr-list.svg" alt="lr-list"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            LR List
          </div>
        </div>
        <div [routerLink]="'/admin/company-settings'"  (click)="hideListText()" class="side-nav-bar__dashboard" *ngIf="
          userDetailsData?.primary &&
          userDetailsData?.company === userDetailsData?.rootCompany">
          <div routerLinkActive="side-nav-bar__active hover-fix" routerLink="/admin/company-settings" ></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Global Settings':''" [placement]="'right'"
            container="body" triggers="mouseenter" class="side-nav-bar__images"
            src="../../../assets/Images/side-nav-bar/settingsImage.svg" alt="settingsImage"/>
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Global Settings
          </div>
        </div>
        <div (click)="hideListText()" [routerLink]="'/eway-bill/credentials'"  appAccessControl allowedRoles="ewaybill.view"
             *ngIf="userDetailsData?.enrollmentType !== 'Bank' && user?.primary && userDetailsData?.company === userDetailsData?.rootCompany" class="side-nav-bar__dashboard">
          <div routerLinkActive="side-nav-bar__active" routerLink="/eway-bill/credentials"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'e-Way Bill':''" [placement]="'right'"
            container="body" class="side-nav-bar__images" triggers="mouseenter"
            src="../../../assets/Images/side-nav-bar/lock.svg" alt="lock"/>
          <div (click)="scrollTo()" [hidden]="hideText" class="side-nav-bar__contentText">
            e-Way Bill
          </div>
        </div>
        <div [routerLink]="'/iba'" (click)="hideListText()" *ngIf=" userDetailsData?.enrollmentType ==='Admin'" class="side-nav-bar__dashboard">
          <div routerLinkActive="side-nav-bar__active" routerLink="/iba" ></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'IBA':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/bank.svg" alt="bank"/>
          <div (click)="scrollTo()" [hidden]="hideText" class="side-nav-bar__contentText">
            IBA
          </div>
        </div>
        <div (click)="hideListText()" *ngIf="userDetailsData?.enrollmentType !== 'Bank' && user?.primary && userDetailsData?.company === userDetailsData?.rootCompany" [routerLink]="'/einvoice'" appAccessControl allowedRoles="einvoice.view"
              class="side-nav-bar__dashboard">
          <div routerLinkActive="side-nav-bar__active" routerLink="/einvoice"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'e-Invoice':''" [placement]="'right'"
            container="body"
            triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/invoice.svg" alt="invoice"/>
          <div (click)="scrollTo()" [hidden]="hideText" class="side-nav-bar__contentText">
            e-Invoice
          </div>
        </div>
      </div>
      <div>
        <button [ngClass]="{
            'side-nav-bar__button': buttonText === '<',
            'side-nav-bar__toggleButton': buttonText === '>'
          }" (click)="toggleTextVisibility()" class="side-nav-bar__button">
          <img *ngIf="!hideText" src="../../../assets/Images/side-nav-bar/closeArrow.svg" alt="Toggle Button" />
          <img *ngIf="hideText" src="../../../assets/Images/side-nav-bar/openArrow.svg" alt="open arrow" />
        </button>
      </div>
      <div [ngClass]="{
          'side-nav-bar__userWrapper': buttonText === '<',
          'side-nav-bar__userSection': buttonText === '>'
        }" class="side-nav-bar__userWrapper">
        <div [ngClass]="{
            'side-nav-bar__user': buttonText === '<',
            'side-nav-bar__admin': buttonText === '>'
          }" class="side-nav-bar__user">
          <div routerLinkActive="side-nav-bar__active" routerLink="/user-setting/user-profile"></div>
          <img *ngIf="profilePic" class="side-nav-bar__userImage cursor-pointer profimg" [src]="profilePic"
            [routerLink]="'/user-setting/user-profile'" data-bs-toggle="tooltip"
            [ngbTooltip]="(showTooltip && userDetailsData?.name)?userDetailsData?.name:''" [placement]="'right'"
            container="body" alt=""/>
          <fa-icon *ngIf="!profilePic" [icon]="userCircleIcon" [routerLink]="'/user-setting/user-profile'"
            class="cursor-pointer side-nav-bar__userImage" style="color: gray; font-size: 40px" data-bs-toggle="tooltip"
            [ngbTooltip]="(showTooltip && userDetailsData?.name)?userDetailsData?.name:''" [placement]="'right'"
            container="body">
          </fa-icon>
          <div [hidden]="hideText" style="padding-left: 20px; padding-top: 30px" class="side-nav-bar__userText">
            {{ userDetailsData?.name }}
          </div>

        </div>
        <div [hidden]="hideText" class="side-nav-bar__userText pt-4" (click)="logout()">
          <app-common-button buttonClass="smallFilledButton" buttonLabel="Logout"
          [loadingIcon]="verifyLoader"></app-common-button>
        </div>
        <div [hidden]="!hideText" class="side-nav-bar__userText" (click)="logout()"
          (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
          data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Log Out':''"
          [placement]="'right'" container="body" triggers="mouseenter"
        >
          <fa-icon [icon]="faSignOut" ></fa-icon>
        </div>
      </div>

    </div>

  </div>
</div>
