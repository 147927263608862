import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-zoom-slider',
  templateUrl: './zoom-slider.component.html',
  styleUrls: ['./zoom-slider.component.scss'],
})
export class ZoomSliderComponent {
  @Output() editZoom: EventEmitter<any> = new EventEmitter<any>();
  zoomLevel = 1;

  decreaseZoom() {
    if (this.zoomLevel > 1) {
      this.zoomLevel--;
      this.editZoom.emit(this.zoomLevel);
    }
  }
  increaseZoom() {
    if (this.zoomLevel < 10) {
      this.zoomLevel++;
    }
    this.editZoom.emit(this.zoomLevel);
  }
  onValueChange(event: any) {
    this.zoomLevel = event;
    this.editZoom.emit(this.zoomLevel);
  }
}
