import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import { ForgotPasswordService } from 'src/app/share/services/forgot-password/forgot-password.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  repassword        : string = '';
  errorMessage      : string | undefined;
  registeredMail    : string = '';
  showForm1         : boolean = true;
  showForm2         : boolean = false;
  showForm3         : boolean = false;
  showPassword      : boolean = false;
  hide              : boolean = true;
  hide2             : boolean = true;
  disabled          : boolean = true;
  forgotPwdLoader   : boolean = false;
  typeBtn           : boolean = true;
  forgotpwdForm    !: FormGroup;
  registerForm2    !: FormGroup;
  otpvalue          : any;
  passwordValue     : any;
  showErrorMessage  : any = {
                              show: false,
                              message: '',
                            };

  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;

  constructor(
    private fb: FormBuilder,
    private forgotPasswordService: ForgotPasswordService
  ) { }

  ngOnInit(): void {
    this.initilizeForgotPwdForm();
    this.initilizeRegisterForm2();
  }

  initilizeForgotPwdForm(): void {
    this.forgotpwdForm = this.fb.group({
      emailAddress: [
        '',
        [Validators.required, Validators.pattern(RegExpPatterns.EmailPattern)],
      ],
    });
    this.registeredMail = '';
  }

  initilizeRegisterForm2(): void {
    this.registerForm2 = this.fb.group(
      {
        otp: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(RegExpPatterns.PasswordPattern),
          ],
        ],
        repassword: ['', [Validators.required, this.passwordsMatchValidatorForForm2]],
      }
    );
  }

  passwordsMatchValidatorForForm2(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const repassword = formGroup.get('repassword')?.value;
    if (password !== repassword) {
      formGroup.get('repassword')?.setErrors({ passwordsMismatch: true });
    } else {
      formGroup.get('repassword')?.setErrors(null);
    }
  }

  onForgotpwd() {
    this.forgotPwdLoader = true;
    this.forgotPasswordService
      .forgotPassword(this.forgotpwdForm?.value)
      .subscribe({
        next: (res) => {
          this.registeredMail =
            this.forgotpwdForm.controls?.['emailAddress']?.value;
          this.showForm1 = false;
          this.showForm2 = true;
          setTimeout(() => {
            this.disabled = false
          }, 900000);
          this.forgotPwdLoader = false;
        },
        error: (error: any) => {
          this.errorMessage = error?.error?.error?.message;
          this.forgotPwdLoader = false;
        }
      }
      );
  }

  resendOtp() {
    this.forgotPwdLoader = true;
    this.forgotPasswordService
      .forgotPassword(this.forgotpwdForm.value)
      .subscribe({
        next: (res) => {
          this.registeredMail =
            this.forgotpwdForm.controls?.['emailAddress'].value;
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false
          }, 900000);
          this.forgotPwdLoader = false;
        },

        error: (error) => {
          this.errorMessage = error?.error?.error?.message;
          this.forgotPwdLoader = false;
        }
      }
      );
  }

  onVerify2() {
    this.forgotPwdLoader = true;
    this.otpvalue = this.registerForm2?.controls['otp'].value;
    this.passwordValue = CryptoJS.SHA256(this.registerForm2?.controls['password'].value).toString(CryptoJS.enc.Hex);
    const payload = {
      emailAddress: this.registeredMail,
      otp: this.otpvalue,
      password: this.passwordValue,

    };
    this.forgotPasswordService.changePassword(payload).subscribe({
      next: (res) => {
        this.showForm2 = false;
        this.showForm3 = true;
        this.showErrorMessage.show = true;
        this.forgotPwdLoader = false;
      },
      error: (error: any) => {
        this.showErrorMessage = {
          show: true,
          message: error?.error?.error?.message
            ? error?.error?.error?.message
            : 'Unable to submit Please try again',
        };
        this.forgotPwdLoader = false;
      }
    });
  }

}
