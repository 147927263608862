import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxHierarchyModule } from 'ngx-hierarchy';
import { QuillModule } from 'ngx-quill';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { NotificationScreenComponent } from './Notification/notification-screen/notification-screen.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { GlobalSettingPreviewComponent } from './global-setting/global-setting-preview/global-setting-preview.component';
import { InfoCardsComponent } from './global-setting/info-cards/info-cards.component';
import { NotifcationSettingsComponent } from './global-setting/notifcation-settings/notifcation-settings.component';
import { OrganisationDataComponent } from './global-setting/organisation-data/organisation-data.component';
import { HeaderComponent } from './header/header.component';
import { HomeModule } from './home/home.module';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { OrgDetailsCardComponent } from './organisation-structure/common/org-details-card/org-details-card.component';
import { OrgDetailsSecondStepComponent } from './organisation-structure/common/org-details-second-step/org-details-second-step.component';
import { ZoneCardComponent } from './organisation-structure/common/zone-card/zone-card.component';
import { PackagesTableCardComponent } from './package-listing/common/packages-table-card/packages-table-card.component';
import { PackagesTypeCardComponent } from './package-listing/common/packages-type-card/packages-type-card.component';
import { RedirectionFailureComponent } from './redirection-failure.component';
import { RedirectionSuccessComponent } from './redirection-success.component';
import { UserGlobalSettingComponent } from './setting-and-preferences/user-global-setting/user-global-setting.component';
import { UserSettingHeaderComponent } from './setting-and-preferences/user-setting-header/user-setting-header.component';
import { HttpInterceptorInterceptor } from './share/services/interceptor/http-interceptor.interceptor';
import { SharedModule } from './share/shared.module';
import { SideNavBarComponent } from './utilities/side-nav-bar/side-nav-bar.component';

   @NgModule({
   declarations: [
    AppComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    RedirectionFailureComponent,
    RedirectionSuccessComponent,
    ZoneCardComponent,
    SideNavBarComponent,
    OrgDetailsCardComponent,
    OrgDetailsSecondStepComponent,
    PackagesTableCardComponent,
    PackagesTypeCardComponent,
    GlobalSettingPreviewComponent,
    InfoCardsComponent,
    MyProfileComponent,
    NotifcationSettingsComponent,
    UserGlobalSettingComponent,
    OrganisationDataComponent,
    UserSettingHeaderComponent,
    NotificationScreenComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HomeModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    PaginatorModule,
    OverlayPanelModule,
    MultiSelectModule,
    CalendarModule,
    NgxHierarchyModule,
    NgCircleProgressModule.forRoot(),
    QuillModule.forRoot()
  ],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
