import {Component, EventEmitter, Input, Output} from '@angular/core';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-common-package-header',
  templateUrl: './common-package-header.component.html',
  styleUrls: ['./common-package-header.component.scss']
})
export class CommonPackageHeaderComponent {
  @Input() createPackageHeader  : string;
  @Input() draftId              : string;
  @Input() packageId            : string;
  @Input() draftStatus          : string;
  @Input() selectionMode        : string;
  @Input() showButton           : string;
  @Input() isChecker            : boolean;
  @Input() loadingIcon          : boolean;
  @Output() saveAsDraft         = new EventEmitter<any>();
  buttonClicked: boolean        = true
  protected readonly faSpinner  = faSpinner;

  sendToDrafts(){
    this.loadingIcon = true;
    if(this.buttonClicked)
    this.saveAsDraft.emit()
    this.buttonClicked = false;
  }
}
