import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-package-file-card',
  templateUrl: './package-file-card.component.html',
  styleUrls: ['./package-file-card.component.scss']
})
export class PackageFileCardComponent implements OnChanges {
  @Input() addedFilesData:any;
  @Output() selectedDocs : EventEmitter<any> = new EventEmitter<any>
ngOnChanges(changes: SimpleChanges): void {
    this.addedFilesData = changes['addedFilesData'].currentValue;
}
  delete(){
    this.selectedDocs.emit({selectedCardData:this.addedFilesData.index,type:'delete'})
  }
  edit(selectedCard:any){
    this.selectedDocs.emit({selectedCardData:selectedCard,type:'edit'})
  }
}
