import { Component } from '@angular/core';

@Component({
  selector: 'app-user-setting-header',
  templateUrl: './user-setting-header.component.html',
  styleUrls: ['./user-setting-header.component.scss']
})
export class UserSettingHeaderComponent {

}
