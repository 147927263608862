import { Component, Input } from '@angular/core';

interface Verify {
  name: string;
  id_label: string;
  id_value: string;
  date_label: string;
  date_value: string;
  verification: string;
  icon: string;
  status: boolean;
}

@Component({
  selector: 'app-info-cards',
  templateUrl: './info-cards.component.html',
  styleUrls: ['./info-cards.component.scss']
})
export class InfoCardsComponent {
  @Input() verified: Verify;


}
