<div class="blockchain__container">
  <div class="blockchain__contentWrapper">
    <div class="blockchain__headerWrapper">
      <div class="blockchain__header">
        <div class="blockchain__title">Generate Blockchain Key</div>
        <img
          class="blockchain__crossIcon"
          src="../../../../assets/Images/organisation-structure/Cross.svg"
          alt="cross icon"
          (click)="onCloseIconClick()"
        />
      </div>
    </div>
    <div class="blockchain__content">
      <div>
        <div class="blockchain__generateKey">
          Generate your blockchain key for transfers
        </div>
        <div
          class="blockchain__resendKeyCard"
          *ngIf="!showSuccessBlockchainMsg"
        >
          <div class="bloackchain__icon">
            <img
              src="../../../../assets/Images/onboarding-dashboard/Verified.svg"
              alt="verified icon"
            />
          </div>
          <div class="bloackchain__details">
            <div class="bloackchain__sendText">Key successfully sent to {{  userDetailsData.emailAddress }}</div>
            
          </div>
        </div>
      </div>
      <div class="text-danger">{{errorMsg}}</div>
      <div class="blockchain__verifyKycCard">
       
        <div class="blockchain__verifyKycCard" *ngIf="showSuccessBlockchainMsg">
          <app-common-button
            buttonLabel="Generate"
            (clickEvent)="createBlockchain()"
            [loadingIcon] = "loadingIcon"
            buttonClass="smallbutton"
          ></app-common-button>
        </div>
      </div>
    </div>
  </div>
</div>
