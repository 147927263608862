import { Component } from '@angular/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent {

  FooterDetailsData=[{
  Logo:"assets\\Images\\my-profile\\BlockchainKey.svg",
  Head:"Blockchain Key",
  Body:"Keep your 64 digits blockchain key safe and do not share with anyone.",
  FooterLogo:"assets\\Images\\my-profile\\Verified.svg",
  Footertext:"Blockchain key verified successfully."
},
{
  Logo:"assets\\Images\\my-profile\\E-Signature.svg",
  Head:"E-Sign",
  Body:"Aadhar Number XXXX XXXX 2321",
  FooterLogo:"assets\\Images\\my-profile\\Verified.svg",
  Footertext:"E-Signature and E-KYC verified successfully."
}
]

}
