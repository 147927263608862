
<div [ngClass]="{'active': packageData?.isActive}" class="packageListingPackageType__main">
    <div class="packageListingPackageType__Wrapper">
        <div class="packageType__image">
            <img [src]="packageData?.imgSrc" alt="all">
        </div>
        <div class="packageType__details">
            <div class="packageType__numberOfPackages">{{packageData?.totalPackages}}</div>
            <div class="packageType__typeOfPackage">{{packageData?.packageType}}</div>
        </div>
    </div>
    <div class="packageType__bottomBorder"></div>
</div>
