import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  onCloseIconClick() {
    this.closePanel.emit();
  }
  roleOptions: string[] = ['Admin', 'Manager', 'Team Member', 'Buyer'];
}