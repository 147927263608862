import { Component,Output,EventEmitter } from '@angular/core';
@Component({
  selector: 'app-filter-offcanvas',
  templateUrl: './filter-offcanvas.component.html',
  styleUrls: ['./filter-offcanvas.component.scss']
})
export class FilterOffcanvasComponent {
  date: Date;
  
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  onCloseIconClick() {
    this.closePanel.emit();
  }
}
