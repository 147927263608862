import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, interval } from 'rxjs';
import { PackageService } from 'src/app/share/services/package-service/package.service';

@Component({
  selector: 'app-blockchain-transfer-card',
  templateUrl: './blockchain-transfer-card.component.html',
  styleUrls: ['./blockchain-transfer-card.component.scss']
})
export class BlockchainTransferCardComponent implements OnInit {
  @Input() statusIconSrc      : any;
  @Input() statusSrc          : string;
  @Output() closePanel        : EventEmitter<void> = new EventEmitter<void>();
  blockchainTransfer          : boolean;
  processTwo                  : string;
  processOne                  : string;
  successProcessing:any ='../../../assets/Images/create-package/statusProcessing.svg'
  private apiSubscription: Subscription = new Subscription();

  constructor(
    private activeModal    : NgbActiveModal,
    private packageService : PackageService,
    private router         : Router) { }

  ngOnInit() {
    this.processOne = 'Waiting';
    this.processTwo = 'Waiting';
    this.blockchainTransfer = this.statusIconSrc?.blockchainTransfer;
    this.getPackageStatus(this.statusIconSrc?._id);
  }

  closePopup() {
    this.activeModal.close();
    this.closePanel.emit();
    this.router.navigate(['packages/incoming'],{
      queryParams: {step:2}});
  }

  getPackageStatus(packageId: string) {
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
    this.apiSubscription = interval(5000).subscribe(() => {
      this.processOne = 'Sent';
      this.packageService.getPackageStatus(packageId).subscribe((res: any) => {
        if (res.data.status === 'Sent' || res.data.status === 'Error') {
          this.processTwo = res.data.status ;
          this.apiSubscription.unsubscribe();
          setTimeout(() => {
            this.closePopup();
          }, 5000);
        } else if(res.data.status === 'Waiting' || res.data.status=== 'Transferring') {
          this.processTwo = 'Waiting';

        }else{
          this.apiSubscription.unsubscribe();
         
          setTimeout(() => {
            this.closePopup();
          }, 5000);
        }
      });
    });
  }
}
