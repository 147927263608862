import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-card-row',
  templateUrl: './notification-card-row.component.html',
  styleUrls: ['./notification-card-row.component.scss']
})
export class NotificationCardRowComponent {
  @Input() messagestatus:any;
  unRead=false

}
