<div class="textarea__container">
  <div class="{{className || 'textarea__heading'}}">
    {{label}}<span *ngIf="starIcon" class="spmred">{{starIcon}}</span>
  </div>
  <textarea 
     class="textarea__inputfield" 
     [formControl]="control" 
     name="package-description" 
     rows="5"
     placeholder={{placeholder}}>
  </textarea>
</div>