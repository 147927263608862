<div class="custom-dropdown">
  <div [ngClass]=" addUser? 'users-dropdown-label' : 'dropdown-label' " (click)="toggleDropdown()">
    <div class="dropdownText">{{ selectedOption }}</div>
    <img src="../../../assets/Images/user-management/dropdown__arrow.svg" alt="arrow">
  </div>
  <div class="dropdown-menu" *ngIf="isDropdownOpen" [class.show]="isDropdownOpen">
    <div class="dropdown-option" *ngFor="let option of options">
      <input type="checkbox" [id]="'checkbox-' + option.value" [checked]="selectedOptions.includes(option)"
        (change)="toggleOption($event, option)" />
      <label>{{ option.name }}</label>
    </div>
  </div>
</div>
