<div class="header-component theme-bg-color sticky-top">
  <div class="row">
    <div class="col-md-2">
      <img
        src="https://axivise-images.s3.ap-south-1.amazonaws.com/app/sdex-logo.png" alt="sdex"
      />
    </div>
    <div class="col-md-8">
      <fa-icon [icon]="faBars" class="bg-white"></fa-icon>
      <span *ngIf="user?.assignedTo?.length > 1">
        <select
          class="select w-50 p-2"
          [(ngModel)]="selectedCompany"
          (change)="onCompanyChange($event)"
        >
          <option
            *ngFor="let company of user.assignedTo"
            [value]="company?.company._id"
          >
            {{ company?.company.name }}
          </option>
        </select>
      </span>
      <span *ngIf="user?.assignedTo?.length === 1">
        <span class="col-md-5 text-white text-truncate fw-bold p-lg-4">
          {{ user?.assignedTo[0]?.company?.name | uppercase }}
        </span>
      </span>
    </div>
    <div
      class="col-md-2 text-white float-end cursor-pointer"
      (click)="signOut()"
    >
      <fa-icon [icon]="faSignOut"></fa-icon>
    </div>
  </div>
</div>
