import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OrganisationStructureService } from 'src/app/share/services/organisation-structure.service';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnChanges {
  @Input() randomID           : any;
  @Input() selectedOrgsRoles  : any;
  @Input() isSelectOrgs       : any;
  @Input() selectedOrgsOnUser : any=[];
  @Input() companyData        : any;
  @Input() isOrgSearch        : boolean = false;
  @Input() resetBranch        : boolean = false;
  @Input() isAutoSearchReset  : any;
  @Output() openDropdownPopup : EventEmitter<any> = new EventEmitter<any>();
  @Output() selectOrgs        : EventEmitter<any> = new EventEmitter<any>();
  @Output() openOrgsStructure : EventEmitter<any> = new EventEmitter<any>();
  @Output() selectOrgsPackage : EventEmitter<any> = new EventEmitter<any>();
  searchText                  : string = '';
  data                        : any;
  isSearchPopup               : boolean = false;
  selectedOrgs                : any = [];
  selectedRoles               : any = [];
  selectedIDs                 : any = [];
  packageOrgs                 : any = [];
  packageOrgsData             : any = [];
  constructor(private orgStructureService: OrganisationStructureService,) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.companyData) {
      this.packageOrgs = [];
      this.getOrg(this.companyData);
    }
    if (this.randomID) {
      this.isSearchPopup = false;
    }
    if(this.isAutoSearchReset){
      this.searchText = '';
    }
    if(this.resetBranch){
      this.selectedOrgsOnUser = [];
      this.selectedOrgs = [];
      this.selectedIDs = []
    }
    if(this.selectedOrgsRoles){
      this.selectedOrgs = this.selectedOrgsRoles;
      this.selectOrgs.emit(this.selectedOrgs);
    }
  }

  onChange(searchText: string) {
    this.isSearchPopup = true;
    if (searchText) {
      this.orgStructureService.searchOrganisation(searchText).subscribe(res => {
        this.data = res.data;
        this.data = this.data.map((x: any) => {
          return { ...x, selectedRoles: [] }
        })
        this.patchUserSelectedOrgs();
        if (this.selectedOrgs.length > 0 && this.data.length > 0) {
          this.existsOrgsRolesSelected();
        }
      })
    } else {
      this.isSearchPopup = false;
    }
  }

  selectOrganisation(value: any) {
    if (value.selected) {
      if (this.checkExistsSelected(value._id)) {
        this.selectedIDs.push(value._id);
        this.selectedOrgs.push(value);
        this.selectOrgs.emit(this.selectedOrgs);
      }
    } else {
      const idindex = this.selectedIDs.indexOf(value._id);
      let index = this.selectedOrgs.findIndex((d: any) => d._id === value._id);
      this.selectedIDs.splice(idindex, 1);
      this.selectedOrgs.splice(index, 1);
      this.selectOrgs.emit(this.selectedOrgs);
    }
  }

  checkExistsSelected(id: any) {
    if (this.selectedIDs.includes(id)) {
      return false;
    } else {
      return true;
    }
  }

  selectRole(checked: boolean, value: any, role: any) {
    value.roles = value.roles.map((r: any) => {
      if (r._id == role._id) {
        return { ...r, selected: checked }
      } else {
        return r;
      }
    })
    if (this.selectedOrgs.length > 0) {
      this.selectedOrgs.map((x: any) => {
          if (x._id == value._id) {
            x.roles = value.roles;
          }
          if (x.isAdmin) {
            x.selectedRoles = [];
          } else
          if (x.roles) {
            x.selectedRoles = x.roles.filter((e: any) => e.selected);
          }
           })
       }
    this.selectOrgs.emit(this.selectedOrgs);
  }

  selectAdminRole(checked: boolean, value: any) {
    this.selectedOrgs = this.selectedOrgs.map((x: any) => {
        if (x._id == value._id) {
          return { ...x, isAdmin: checked }
        } else {
          return x;
        }
    })
    this.selectOrgs.emit(this.selectedOrgs);
  }


  existsOrgsRolesSelected() {
    this.data = this.data.map((x: any) => {
      if (this.selectedOrgs.filter((y: any) => y._id.indexOf(x._id) !== -1).length > 0) {
        let orgs = this.selectedOrgs.filter((org: any) => org._id == x._id)[0];
        let roles = orgs.roles.map((r: any) => {
          if (r.selected) {
            return { ...r, selected: false };
          } else {
            return r;
          }
        })
        x.isAdmin = orgs.isAdmin;
        x.roles = orgs.isAdmin ? roles : orgs.roles;
        return { ...x, selected: true }
      } else {
        return x;
      }
    })
  }

  patchUserSelectedOrgs() {
    this.data = this.data?.map((x: any) => {
      if (this.selectedOrgsOnUser.filter((y: any) => y._id.indexOf(x._id) !== -1).length > 0) {
        let orgs = this.selectedOrgsOnUser.filter((org: any) => org._id == x._id)[0];
        if(orgs?.selectedRoles){
          x.roles = x.roles.map((r: any) => {
            let role = orgs?.selectedRoles.filter((role: any) => role._id == r._id)[0];
            if (role) {
              return { ...r, selected: true };
            } else {
              return r;
            }
          })
        }
        return {
          ...x, isAdmin: orgs.isAdmin,
          selectedRoles: orgs?.selectedRoles,
          selected: true
        }
      }
      else {
        return { ...x, selectedRoles: [] }
      }
    })

    if (this.selectedOrgsOnUser.length > 0) {
      this.selectedOrgsOnUser.forEach((x: any) => {
        this.selectExistsOrgs(x);
      })
    }
  }

  selectExistsOrgs(value: any) {
    if (this.checkExistsSelected(value._id)) {
      this.selectedIDs.push(value._id);
      this.selectedOrgs.push(value);
    }
  }

  openOrganisationStructureOverlay() {
    this.openOrgsStructure.emit(true);
  }

  openSearchPopup() {
    this.openDropdownPopup.emit('dropdownpopup');
  }

  getOrg(data: any = []) {
    data.forEach((x: any) => {
      if (this.packageOrgs.filter((f: any) => f._id === x._id).length === 0) {
        this.packageOrgs.push({ _id: x._id, name: x.name })
      }
      if (x.childs?.length > 0) {
        this.getOrg(x.childs)
      }
    })
    this.packageOrgsData = this.packageOrgs;
  }

  handleFilter(value: string) {
    this.isSearchPopup = true;
    if (value) {
      this.packageOrgsData = this.packageOrgs.filter((s: any) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.isSearchPopup = false;
    }
  }

  selectOrgPackage(value: any){
    this.selectOrg(this.companyData,value._id)
    this.isSearchPopup = false;
    this.searchText = '';
  }

  selectOrg(data: any, id: string) {
    data?.map((x: any) => {
      if (x._id === id) {
        this.selectOrgsPackage.emit(x)
      } else
        if (x.childs.length > 0) {
          this.selectOrg(x.childs, id)
        }
     })
  }

  protected readonly faCircleCheck = faCircleCheck;
}
