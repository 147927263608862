import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class DashBoardService {
    public ReloadEmitter: EventEmitter<any>;

    constructor() {
        this.ReloadEmitter = new EventEmitter();
    }

    setReload(value: any): void {
        this.ReloadEmitter.emit(value)
    }

    getReload(){
        return this.ReloadEmitter;
    }

}