<div class="admin-steps-card__main">
  <div class="admin-steps-card__top">
    <div class="admin-steps-image">
      <img [src]="cardData?.imageSrc" [alt]="cardData?.title" />
    </div>
    <div class="admin-steps__title">{{ cardData?.title }}</div>
    <div class="admin-steps__description">
      {{ cardData?.description }}
    </div>
  </div>
  <div class="admin-steps-card__middleLine"></div>
  <div class="admin-steps-card__bottom">
    <div [ngClass]="{
        'admin-card__countValue': cardData?.countValue != 'View Added Network',
        'admin-card__countValueUpdated':
          cardData?.countValue === 'View Added Network'
      }">
      {{ cardData?.countValue }}
    </div>
    <div *ngIf="cardData?.buttonLabel">
      <app-common-button  [buttonClass]="
        cardData?.buttonLabel === 'Verified' ||
        cardData?.buttonLabel === 'Generated'
          ? 'button_sucess'
          : cardData?.buttonType
      " [disabled]="isDisabled" [buttonLabel]="cardData?.buttonLabel"
      (clickEvent)="onClickEvent(cardData.buttonLabel)"></app-common-button>
    </div>
    <div *ngFor="let label of cardData?.labels" appAccessControl allowedRoles="{{label.roles}}" restrictedRoles="{{label.restrictedRoles || ''}}">
      <app-common-button  [buttonClass]="
        cardData?.buttonLabel === 'Verified' ||
        cardData?.buttonLabel === 'Generated'
          ? 'button_sucess'
          : cardData?.buttonType
      " [disabled]="isDisabled" [buttonLabel]="label?.buttonLabel"
                          (clickEvent)="onClickEvent(label.buttonLabel)"></app-common-button>
    </div>
  </div>
</div>
