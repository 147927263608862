import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-error-toaster',
  templateUrl: './error-toaster.component.html',
  styleUrl: './error-toaster.component.scss'
})
export class ErrorToasterComponent {
  @Input() title          : string;
  @Output() closeToaster  : EventEmitter<any> = new EventEmitter<any>();
  delayMilliseconds       : number;
  showsToast              : boolean = true;

  constructor() {
    this.startCloseTimer();
  }

  //Setting the timer for automatically closing the toaster
  startCloseTimer() {
    const delayMilliseconds = 5000;
    setTimeout(() => {
     this.close();
    }, delayMilliseconds);
  }

  close() {
    this.showsToast = false;
    this.closeToaster.emit(false);
  }
}
