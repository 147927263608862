<div class="orgDetailsStep2__container">
  <div class="orgDetailsStep2__contentWrapper">
    <div class="orgDetailsStep2__header">
      <div class="orgDetailsStep2__title">Organisation Details</div>
      <div class="orgDetailsStep2__headerIcons">
        <img
          class="orgDetailsStep2__editIcon"
          src="../../../../assets/Images/organisation-structure/edit.svg"
          alt="edit icon"
        />
        <div class="orgDetailsStep2__headerIcons-divider"></div>
        <img
          (click)="onCloseIconClick()"
          class="orgDetailsStep2__crossIcon"
          src="../../../../assets/Images/organisation-structure/Cross.svg"
          alt="cross icon"
        />
      </div>
    </div>
    <div class="orgDetailsStep2__content">
      <div class="orgDetailsStep2__orgDetails">
        <div class="orgDetailsStep2__orgDetailsTitle">Basic Details</div>
        <div class="orgDetailsStep2__orgName">
          <app-org-details-card
            title="Organisation Name"
            description="Tata Steel"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__email-id">
          <app-org-details-card
            title="Organisation Email"
            description="contact@tatasteel.com"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__phoneNumber">
          <app-org-details-card
            title="Organisation Phone Number"
            description="+91 67534 25678"
          ></app-org-details-card>
        </div>
      </div>
      <div class="orgDetailsStep2__orgAddress">
        <div class="orgDetailsStep2__orgAddressTitle">Address</div>
        <div class="orgDetailsStep2__line-1">
          <app-org-details-card
            title="Line 1"
            description="Tisco General Office"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__line-2">
          <app-org-details-card
            title="Line 2"
            description="--"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__locality">
          <app-org-details-card
            title="Locality"
            description="Bistupur"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__landmark">
          <app-org-details-card
            title="Landmark"
            description="--"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__city">
          <app-org-details-card
            title="City"
            description="Jamshedpur"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__state">
          <app-org-details-card
            title="State"
            description="Jharkhand"
          ></app-org-details-card>
        </div>
        <div class="orgDetailsStep2__pincode">
          <app-org-details-card
            title="Pincode"
            description="831001"
          ></app-org-details-card>
        </div>
      </div>
    </div>
  </div>

  <div class="orgDetailsStep2__footer">
    <app-common-button
      buttonClass="smallbutton"
      buttonLabel="Cancel"
    ></app-common-button>
    <button class="orgDetailsStep2__disabledBtn" disabled>Save</button>
  </div>
</div>
