import { Component, Input } from '@angular/core';

interface Header {
  heading: string;
  subHeading: string;
}

@Component({
  selector: 'app-global-setting-header',
  templateUrl: './global-setting-header.component.html',
  styleUrls: ['./global-setting-header.component.scss']
})
export class GlobalSettingHeaderComponent {

  @Input() headings: Header

}
