<div class="articleCard__container" (click)="openArticle(articleCardData)">
    <div class="articleCard__cardLogoimg"><img class="articleCard__cardLogo" [src]=articleCardData?.iconSrc
            [alt]=articleCardData?.title></div>
    <div>
        <div class="articlesCard__title">
            {{articleCardData?.title}}
        </div>
        <div class="articlesCard__descrption">
            {{articleCardData?.description}}
        </div>
    </div>
</div>