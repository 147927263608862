import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { environment } from 'src/environments/environment';
import { StorageKeys } from './share/enums/storage.enum';

@Directive({
  selector: '[appAccessControl]',
})
export class AccessControlDirective implements OnInit{
  @Input('allowedRoles') allowedRoles: string;
  @Input('restrictedRoles') restrictedRoles: string;
  @Input('showingCards') showingCards: any[];
  @Input('cardTitle') cardTitle: string;

  constructor(
    private elementRef: ElementRef,
    private storageService: StorageService
  ) {}
  ngOnInit() {
    if(!environment.rolesDisabled){
      this.elementRef.nativeElement.style.display = 'none';
      this.checkAccess();
    }
  }

  checkHasPermission(presentRoles: any, moduleType: string, accessType: string){
    let hasPermission = false;
    for (const presentRole of (presentRoles || [])) {
      for (const permission of (presentRole.permissions || [])) {
        if (permission.module === moduleType) {
          if (permission.subPermissions?.[accessType] || permission.enabled ||
            (accessType === 'view' && Object.keys(permission.subPermissions || {}).length)) {
            hasPermission = true;
            break;
          }
        }
      }
    }
    return hasPermission;
  }

  checkHasRole(userRoles: string, selfDetails: any): boolean {
    let hasRole = false;
    const allowedRoles = (userRoles || '').split(',');
    for (const role of allowedRoles) {
      const moduleType = role.split('.')[0];
      const accessType = role.split('.')[1];
      hasRole = hasRole || this.checkHasPermission(selfDetails?.presentRoles || [], moduleType, accessType);
    }
    return hasRole;
  }

  checkAccess() {
    const selfDetails = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    const enrollmentType = selfDetails?.enrollmentType;
    let hasAccess = enrollmentType === 'Admin';
    const isAllowedRole = this.checkHasRole(this.allowedRoles || '', selfDetails);
    const isRestrictedRole = this.checkHasRole(this.restrictedRoles || '', selfDetails);
    if (isAllowedRole) {
      hasAccess = !isRestrictedRole;
    } else if (selfDetails?.primary || selfDetails?.isAdmin) {
      hasAccess = !(this.allowedRoles && this.restrictedRoles);
    }
    if(selfDetails?.hideActionsNoEsign && this.allowedRoles && (this.allowedRoles.indexOf('.view') === -1) && (this.allowedRoles.indexOf('skipNoActions') === -1)){
      hasAccess = false;
    }
    if(selfDetails?.hideActionsNoBlockchain && this.allowedRoles && (this.allowedRoles.indexOf('.view') === -1) && (this.allowedRoles.indexOf('skipNoActions') === -1)){
      hasAccess = false;
    }
    if(selfDetails?.hideActionsNoEsign && this.allowedRoles && (this.allowedRoles.indexOf('e-sign-details.enabled') !== -1)){
      hasAccess = true;
    }
    if (selfDetails?.hideActionsNoBlockchain && this.allowedRoles && (this.allowedRoles.indexOf('package.checker') !== -1) &&  (this.allowedRoles.indexOf('skipNoActions') !== -1)) {
      hasAccess = true;
    }
    this.updateElementDisplay(hasAccess);
  }

  updateElementDisplay(hasAccess: boolean) {
    this.elementRef.nativeElement.style.display = hasAccess ? 'flex' : 'none';
    if(hasAccess && this.cardTitle && this.showingCards && this.showingCards.indexOf(this.cardTitle) === -1){
      if (!this.showingCards.includes(this.cardTitle)) {
        if (this.cardTitle == 'Blockchain Key') {
          this.showingCards.splice(1, 0, this.cardTitle);
        } else {
          this.showingCards.push(this.cardTitle);
        }
      }
    }
  }

}
