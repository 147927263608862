import { Component } from '@angular/core';

@Component({
  selector: 'app-home-layout',
  template: `

<!--
  <app-header></app-header>
-->
  <!--<div class="row m-0">
    <div class="col-md-2 g-0 vh-100 side-bar theme-bg-color position-fixed ">
&lt;!&ndash;      <app-sidebar></app-sidebar>&ndash;&gt;
      <app-side-nav-bar></app-side-nav-bar>
    </div>
    <div class="offset-2 col-md-10 theme-body-bg-color body-container">
      <router-outlet></router-outlet>
    </div>
  </div>-->
<div class="login-layout">
  <div>
    <app-sidebar></app-sidebar>
  </div>
  <div class="route-section">
    <router-outlet></router-outlet>
  </div>
</div>
<!--
  <app-footer></app-footer>
-->
  `,
  styles: [`.body-container{
    background-color : white
  }
  .offset-2{
    padding-bottom: 60px;
  }
  .side-bar{
    z-index: 99;
  }
  .route-section{
    min-width: calc(100% - 176px);
    flex-grow: 1;
    padding: 36px 29px 19px 26px;
  }`
  ]
})
export class HomeLayoutComponent {}
