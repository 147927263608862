<div class="accountLogin__bodySection">
  <div class="accountLogin__body">
    <div class="accountLogin__section">
      <div class="accountLogin__logo">
        <img src="../../../assets/Images/login/headerLogo.svg" alt="">
        <img src="../../../assets/Images/login/sdexLogo.svg" alt="sdex">
      </div>
      <form (ngSubmit)="onLogin()" name="loginForm" [formGroup]="loginForm">
        <div class="accountLogin__signIn">
          <div class="accountLogin__welcome">Welcome to</div>
          <div class="accountLogin__welcomeHeader">Secured Document Exchange</div>
          <div class="accountLogin__inputWrapper">
            <div class="accountLogin__inputSection">
              <div class="accountLogin__inputLabel">Email</div>
              <app-input-filed [control]="$any(loginForm).controls['username']" placeholder="Email"> </app-input-filed>

            </div>

            <!-- new code one -->
            <div class="accountLogin__inputSection">
              <div class="accountLogin__inputLabel"> Password</div>

              <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                  <input [type]="'password'" id="password" class="usersFilter__input overrideInputfield" style="
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              border-color: #000 !important;
              width: 320px;
            " oncopy="return false" onpaste=" return false" type="{{ hide ? 'password' : 'text' }}"
                    formControlName="password" placeholder="Password" />
                  <fa-icon class="usersFilter__searchIcon" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                    [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
                </div>
              </div>

            </div>
            <!-- new code one -->

          </div>
          <div class="accountLogin__forgotPassword">
            <div class="accountLogin__remember">

              <a style="text-decoration: none;" [routerLink]="['/setup-account']" class="text-link">
                <div class="accountLogin__rememberText">Setup Password</div>
              </a>
            </div>
            <a style="text-decoration: none;" [routerLink]="['/forgot-password']">
              <div class="accountLogin__passwordText">Forgot Password ?</div>
            </a>
          </div>
          <div class="accountLogin__button">
            <app-common-button buttonClass="smallFilledButton" [buttonLabel]="'Sign In'"
            custButtonClass="custButtonClass" [loadingIcon]="signInLoader" [typeBtn]="true">
            </app-common-button>
          </div>
          <div class="mt-2 text-center text-danger pb-3">
            {{errorLogin}}
          </div>
          <div class="accountLogin__signUpWrapper">

          </div>
        </div>
      </form>
    </div>
    <app-logo></app-logo>

  </div>
</div>