import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bulk-upload-list',
  templateUrl: './bulk-upload-list.component.html',
  styleUrls: ['./bulk-upload-list.component.scss']
})
export class BulkUploadListComponent {
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  onCloseIconClick() {
    this.closePanel.emit();
  }

  bulkData = [
    {
      name:'Vikas Tiwari',
      email:'vikastiwari@tatasteel.com',
      number:'9633906266',
      roles:'Admin',
      branches:'All Branches'
    },
    {
      name:'Anuprakash Moothedath',
      email:'anumoo@tatasteel.com',
      number:'9633906266',
      roles:'Admin, Manager',
      branches:'North zone'
    },
    {
      name:'Mahendra Pratap Singh',
      email:'maheprasi@tatasteel.com',
      number:'8997227880',
      roles:'Team member',
      branches:'4 branches'
    },
    {
      name:'Prasad Krishnapurkar',
      email:'19520102',
      number:'7430105887',
      roles:'Team member',
      branches:'--'
    },
    {
      name:'Suraj Pandey',
      email:'surajpandey@tatasteel.com',
      number:'9689920654',
      roles:'Team member',
      branches:'Delhi'
    },
    {
      name:'Swapnaja Shirode',
      email:'swapnajashi@tatasteel.com',
      number:'9829424447',
      roles:'Admin',
      branches:'Jamshedpur'
    },
  ]

}
