import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tooltip } from 'bootstrap';
import { AuthService } from 'src/app/home/auth/auth.service';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss']
})
export class SideNavBarComponent {
  enrollmentType: any;
  constructor(
    private storageService: StorageService,
    public authService: AuthService,
  ) {}
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSubMenuActive: boolean = false;
  selectedMenuItem: number = 1;
  user: any = {};
  nextLevel: any;
  userName: string | undefined;
  userDetailsData: any;
  profilePic: any;
  defaultProfilePic = `${environment.IMAGE_URL}blank-profile-picture.png`;
  bootstrap: any;
  isShowProfile: boolean = false;
  rolesDisabled = false;
  ngOnInit() {
    this.rolesDisabled = environment.rolesDisabled;
    this.getSelfDetails();
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));

  }

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  toggleSubMenu() {
    this.isSubMenuActive = !this.isSubMenuActive;
  }

  isMenuItemActive(index: number): boolean {
    return this.selectedMenuItem === index;
  }

  getSelfDetails() {
    this.authService.getSelfDetails().subscribe((res) => {
      this.storageService.setDataToLocalStorage(
        StorageKeys.SELF_DETAILS,
        res.data
      );
      this.userDetailsData = res.data;
      this.profilePic = this.userDetailsData?.profileImage;
      if (this.userDetailsData.enrollmentType !== 'Admin') {
        this.getOrganisationStructureById(
          this.userDetailsData.company,
          this.userDetailsData.assignedTo
        );
      }
    });
  }

  getOrganisationStructureById(companyId: string, assignedTo: any) {
    this.authService
      .getOrganizationStructureById(undefined)
      .subscribe((res: any) => {
        if (
          res?.data?.structure?.length
        ) {
          if (res.data.rootCompany === companyId) {
            this.nextLevel = res.data.structure[0];
          } else if (assignedTo?.[0]?.company?.structure) {
            let level = assignedTo[0].company.structure.level;
            if (res.data.structure[level]) {
              this.nextLevel = res.data.structure[level];
            }
          }
        }
      });
  }
  hideText: boolean = false;
  buttonText: string = 'arrow.svg';
  openingArrow: string= 'openArrow.svg';

toggleTextVisibility() {
  this.hideText = !this.hideText;
  this.buttonText = this.hideText ? '' : 'arrow.svg'; // Replace with the paths or names of the images
}
}
