<div *ngIf="showsToast" class="toast__container">
  <div class="toast__icon">
    <img
      src="../../../assets/Images/roles-responsibilities/verify.svg"
      alt=""
    />
  </div>
  <div class="toast__text">{{ title }}</div>
  <div class="toast__cancelIcon" (click)="closeToast()">
    <img
      src="../../../assets/Images/roles-responsibilities/cancel.svg"
      alt=""
    />
  </div>
</div>
