import { Component, EventEmitter, Input, OnChanges , Output } from '@angular/core';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import { PackageService } from 'src/app/share/services/package-service/package.service';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss']
})
export class DropdownSearchComponent implements OnChanges {
  @Input() label:string;
  @Input() notifyUsersDataForCardsData:any;
  @Input() selectedRootCompanyId:any;
  @Input() randomID:any;
  @Output() notifyUsersData : EventEmitter<any> = new EventEmitter<any>();
  @Output() openPopup       : EventEmitter<any> = new EventEmitter<any>();
  searchSuggestionsData : any[];
  searchText:string;
  notifyContacts: any;
  emailRegex = new RegExp(RegExpPatterns.EmailPattern);

  notifyContactsList: number;
// Used this for UI visibility purpose
constructor(private packageService:PackageService, private userSettingService: UserSettingService){ }

  resetSearch(){
    this.searchText = '';
    this.searchSuggestionsData = [];
  }

  ngOnChanges(): void {
    if (this.randomID) {
      this.resetSearch();
    }
  }

  addContactToNotify() {
    this.notifyContacts = { emailAddress: this.searchText, isChecked: true };
    this.notifyUsersData.emit(this.notifyContacts);
    this.resetSearch();
  }

  searchUser(searchText: string){
    let searchObj = {
      emailAddress: searchText,
      selectedCompany: this.selectedRootCompanyId
    }

    this.userSettingService.searchUserInSystem(searchObj)?.subscribe((res : any)=>{
      this.searchSuggestionsData = (res.data || []);
      this.searchSuggestionsData = this.searchSuggestionsData.map(user => {
        if (this.alreadyAdded(user.emailAddress)) {
          return { ...user, isChecked: 'true' }
        } else {
          return user;
        }
      })
    })
  }

  alreadyAdded(emailAddress: string) {
    if (this.notifyUsersDataForCardsData?.length && emailAddress) {
      for (const user of this.notifyUsersDataForCardsData) {
        if (user.emailAddress.toLowerCase() === emailAddress.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  }


  handleStatusSelection(e: any, user: any) {
      this.notifyUsersData.emit({
        name: user.name,
        profileImage: user.profileImage,
        rootCompany: user.rootCompany?.name,
        emailAddress: user.emailAddress,
        isChecked: e.event.target.checked
      })

  }
  openSearchPopup(){
    this.openPopup.emit('popupOpend');
  }
}
