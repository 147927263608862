<!-- Dropdown with search component -->
<div class="createPackage__dropdownSearchComponent">
  <label for="userDropdown" class="createPackage__dropdown-label">
    {{ label }}
  </label>
  <div class="createPackage__dropdownSearchMain">
    <div class="createPackage__search">
      <div class="createPackage__search-image">
        <img src="../../../../assets/Images/create-package/search.svg" alt="search" class="createPackage__search-img" />
      </div>
      <input type="email" (keyup)="searchUser(searchText)"  class="createPackage__input-field"
        [(ngModel)]="searchText"/>
      <!-- above search validation is added of UI purpose, not correct validations -->
    </div>
  </div>
  <div (click)="openSearchPopup()" id="searchpopup" class="createPackage__searchSuggestions" *ngIf="searchText !== ''">
    <!-- If the Users Found  -->
    <div class="createPackage__searchUserDetails" *ngFor="let value of searchSuggestionsData">
      <div class="createPackage__searchUserWrapper">
        <div class="createPackage__searchUserImage">
          <img style="border: 1px solid white ; border-radius: 50%;"
            [src]="value.profileImage || 'assets/Images/default.png'" alt="user" width=100px height=100px />
        </div>
        <div class="createPackage__searchUserNameLocation">
          <div class="createPackage__searchUserName-UserType">
            {{ value.name }} ({{ value.emailAddress }})
          </div>
          <div class="createPackage__searchUserLocation">
            {{ value.rootCompany?.name }}
          </div>
        </div>
      </div>
      <div class="createPackage__searchUseraddedinfoimage">
        <!-- If the user is added then the added image is displayed or add image will be displayed -->
        <app-checkbox [checkboxStatus]="value.isChecked" (checked)="handleStatusSelection($event, value)"></app-checkbox>

      </div>
    </div>
    <!-- If the User not found -->
    <!-- Below ngclass validations are added for UI purpose -->
    <div class="createPackage__searchNoDetails" *ngIf="!(searchSuggestionsData || []).length && searchText">
      <div class="createPackage__NoDetailsData">
        The user <b>“{{ searchText }}”</b> does not exist. You can still add the
        email id to send a notification.
      </div>
      <div *ngIf="searchText && emailRegex.test(searchText)" class="createPackage__InviteUser"
        (click)="addContactToNotify()">
        <img src="../../../../assets/Images/create-package/Addor.svg" alt="add" />
       Add External Email
      </div>
    </div>
  </div>
</div>
