<!-- updated -->
<section class="organisationalDetails__container">
  <div class="organisationalDetails__heading">Organisation Details</div>
  <div class="organisationalDetails__details">
    <div class="organisationalDetails__imageContainer" *ngIf="isEditprofile">
      <div class="profile-image" (click)="file.click()">
        <ng-container *ngIf="profileImage; else altImage">
          <img src="{{ organisationDetailsData?.profileImage }}" alt="Image">
          <span><fa-icon [icon]="faCamera"></fa-icon></span>
        </ng-container>
        <ng-template #altImage>
          <fa-icon [icon]="userCircleIcon" style="color:gray;
            font-size:100px;">
          </fa-icon>
          <span><fa-icon [icon]="faCamera"></fa-icon></span>
        </ng-template>
      </div>
      <div class="profile-change">
        <input style="display: none" #file type="file" accept="image/*,jpg,png" (change)="upload($event)" />
      </div>
    </div>

    <div class="organisationalDetails__imageContainer" *ngIf="!isEditprofile">
      <div class="profile-image">
        <ng-container *ngIf="profileImage; else altImage">
          <img src="{{ organisationDetailsData?.profileImage }}" alt="Image">
        </ng-container>
        <ng-template #altImage>
          <fa-icon [icon]="userCircleIcon" style="color:gray;
            font-size:80px;">
          </fa-icon>
        </ng-template>
      </div>
    </div>


    <div class="organisationalDetails__infobox">
      <div class="organisationalDetails__data">
        <div class="organisationalDetails__title">
          Tata Steel Pvt. Ltd.
        </div>
        <div class="mdc-card" *ngIf="edit">
          <div class="row mb-2">
            <div class="col">
            </div>
          </div>
          <div>
            <form [formGroup]="organisationProfileForm" (ngSubmit)="saveProfile()">
              <div class="row mb-3">
                <div class="col-md-4" style="margin-right:20px;">
                  <label class="organisationalDetails__infobox_label">Address One</label>
                  <input class="organisationalDetails__infobox_input" placeholder="Addressone" input type="text"
                    formControlName="addressone" required maxlength="50" />
                </div>
                <div class="col-md-4">
                  <label class="organisationalDetails__infobox_label">Address Two</label>
                  <input class="organisationalDetails__infobox_input" placeholder="Addresstwo" input type="text"
                    formControlName="addresstwo" required maxlength="50" />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4" style="margin-right:20px;">
                  <label class="organisationalDetails__infobox_label">Address Three</label>
                  <input class="organisationalDetails__infobox_input" placeholder="Addressthree" input type="text"
                    formControlName="addressthree" required maxlength="50" />
                </div>
                <div class="col-md-4">
                  <label class="organisationalDetails__infobox_label">Address Four</label>
                  <input class="organisationalDetails__infobox_input" placeholder="Addressfour" input type="text"
                    formControlName="addressfour" required maxlength="50" />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label class="organisationalDetails__infobox_label">Address Five</label>
                  <input class="organisationalDetails__infobox_input" placeholder="Addressfive" input type="text"
                    formControlName="addressfive" required maxlength="50" />
                </div>
              </div>
              <div class="d-flex gap-2 mt-4">
                <button class="btn btn-primary ml-2" type="button" (click)="cancel()">
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary ml-2">Update</button>

              </div>
            </form>
          </div>
        </div>
        <div *ngIf="editedprofile">
          <div class="content mb-2 mt-2">Name: {{ organisationDetailsData?.addressone }}</div>
          <div class="content mb-2">Email: {{ organisationDetailsData?.addressone }}</div>
          <div class="content mb-2">GSTIN Number: {{ organisationDetailsData?.addressone }}</div>
          <div class="content mb-2">CIN Number: {{ organisationDetailsData?.addressone }}</div>
          <div>Organisation Address :</div>
          {{organisationProfileForm.value.addressone}}
          {{organisationProfileForm.value.addresstwo}}
          {{organisationProfileForm.value.addressthree}}
          {{organisationProfileForm.value.addressfour}}
          {{organisationProfileForm.value.addressfive}}
        </div>
      </div>
      <div class="organisationalDetails__editbtn" (click)="toggleEdit()" *ngIf="disableinedit">Edit</div>
    </div>
  </div>
</section>

<div *ngIf="usesrProfileedit">
  <div class="p-4 mdc-card mt-3" *ngIf="!edit">
    <div class="Profile">
      <div class="profile-image">
        <img class="profile-pic" *ngIf="organisationDetailsData?.profileImage"
          src="{{ organisationDetailsData?.profileImage }}" alt="" />
        <div *ngIf="!organisationDetailsData?.profileImage">
          <div class="bg-primary native-profile">
            {{ organisationDetailsData?.name?.charAt(0).toUpperCase()}}
          </div>
        </div>
      </div>

      <div class="Details">
        <div class="content">Name: {{ organisationDetailsData?.addressone }}</div>
        <div class="content">Email: {{ organisationDetailsData?.addressone }}</div>
        <div class="content">GSTIN Number: {{ organisationDetailsData?.addressone }}</div>
        <div class="content">CIN Number: {{ organisationDetailsData?.addressone }}</div>
        <div>Organisation Address</div>
        <div class="content">addressone: {{ organisationDetailsData?.addressone }}</div>
        <div class="content">addresstwo: {{ organisationDetailsData?.addresstwo }}</div>
        <div class="content"> addressthree: {{ organisationDetailsData?.addressthree}}</div>
        <div class="content"> addressfour: {{ organisationDetailsData?.addressfour}}</div>
        <div class="content"> addressfive: {{ organisationDetailsData?.addressfive}}</div>
      </div>
    </div>
  </div>
</div>