import { Component, Input } from '@angular/core';

interface Details {
  label: string;
  data: string;
}

@Component({
  selector: 'app-organisation-data',
  templateUrl: './organisation-data.component.html',
  styleUrls: ['./organisation-data.component.scss']
})
export class OrganisationDataComponent {

  @Input() userData: Details;



}
