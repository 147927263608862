import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnChanges {
  @Input() list                      : any[];
  @Input() listData                  : any[];
  @Input() selectedType              : string;
  @Input() multi                     : string;
  @Input() clearSelectedContact      : string;

  @Output() selectedValues           = new EventEmitter();
  @Output() selectedMultiValues      = new EventEmitter();
  showDropDown                       : boolean = false;
  checkedList                        : any[];
  selectedIDs                        : any[];
  contactData                        : any[];

  multiSelectedIDs                   : any = [];
  checkedMultiList                   : any = [];
  multiListData                      : any = [];



  constructor() {
    this.checkedList = [];
    this.selectedIDs = [];
    this.checkedMultiList = [];
    this.multiSelectedIDs = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.multiListData = this.listData;
    this.contactData = this.list;
    if (changes["clearSelectedContact"]) {
      this.clearAll();
      this.multiClearAll();
    }
  }

  getSelectedValue(status: boolean, value: any) {
    if (status) {
      this.selectedIDs.push(value.connectedCompany._id)
      this.checkedList.push(value.connectedCompany.name);
      this.selectedList(status, value._id);
    } else {
      const idindex = this.selectedIDs.indexOf(value.connectedCompany._id);
      this.selectedIDs.splice(idindex, 1);
      const index = this.checkedList.indexOf(value.connectedCompany.name);
      this.checkedList.splice(index, 1);
      this.selectedList(status, value._id);
    }
    this.shareCheckedlist();
  }

  shareCheckedlist() {
    this.selectedValues.emit(this.selectedIDs);
  }

  selectedList(status: boolean, id: any) {
    this.list = this.list.map(x => {
      if (status) {
        if (x._id === id) {
          return { ...x, checked: true };
        } else {
          return x;
        }
      } else  if (x._id === id) {
          return { ...x, checked: false };
        } else {
          return x;
        }

    });
  }

  selectAllValue(status: boolean, value: any) {
    if (status) {
      if (!this.selectedIDs.includes(value.connectedCompany._id)) {
        this.selectedIDs.push(value.connectedCompany._id)
      }
      if (!this.checkedList.includes(value.connectedCompany.name)) {
        this.checkedList.push(value.connectedCompany.name);
      }
    }
    this.shareCheckedlist();
  }

  selectAll(status: boolean) {
    if (status) {
      this.list = this.list.map(x => {
        this.selectAllValue(status, x);
        return { ...x, checked: status };
      });
    } else {
      this.clearAll();
    }
  }

  clearAll() {
    this.checkedList = [];
    this.selectedIDs = [];
    this.list = this.list.map(x => {
      return { ...x, checked: false };
    });
  }

  get isCheckedAll() {
    if (this.list.length === this.selectedIDs.length) {
      return true;
    } else {
      return false;
    }
  }

  handleFilterContact(e: any) {
    this.contactData = this.list.filter((s: any) => s.connectedCompany.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
  }

  // common multiselected dropdown

  getMultiSelectedValue(status: boolean, value: any) {
    if (status) {
      this.multiSelectedIDs.push(value.id)
      this.checkedMultiList.push(value.name);
      this.multiselectedList(status, value.id);
    } else {
      let idindex = this.multiSelectedIDs.indexOf(value.id);
      this.multiSelectedIDs.splice(idindex, 1);
      let index = this.checkedMultiList.indexOf(value.name);
      this.checkedMultiList.splice(index, 1);
      this.multiselectedList(status, value.id);
    }
    this.selectedMultiValues.emit(this.multiSelectedIDs);
  }

  multiselectedList(status: boolean, id: any) {
    this.listData = this.listData.map(x => {
      if (status) {
        if (x.id === id) {
          return { ...x, checked: true };
        } else {
          return x;
        }
      } else  if (x.id === id) {
          return { ...x, checked: false };
        } else {
          return x;
        }

    });
  }

  multiSelectAll(status: boolean) {
    if (status) {
      this.listData = this.listData.map(x => {
        this.multiSelectAllValue(status, x);
        return { ...x, checked: status };
      });
    } else {
      this.multiClearAll();
    }
  }

  multiClearAll() {
    this.checkedMultiList = [];
    this.multiSelectedIDs = [];
    if (this.listData) {
      this.listData = this.listData.map(x => {
        return { ...x, checked: false };
      });
    }
  }

  get isMultiCheckedAll() {
    if (this.listData.length === this.multiSelectedIDs.length) {
      return true;
    } else {
      return false;
    }
  }

  multiSelectAllValue(status: boolean, value: any) {
    if (status) {
      if (!this.multiSelectedIDs.includes(value.id)) {
        this.multiSelectedIDs.push(value.id)
      }
      if (!this.checkedMultiList.includes(value.name)) {
        this.checkedMultiList.push(value.name);
      }
    }
  }

  handleFilter(event: any) {
    this.multiListData = this.listData.filter((s: any) => s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
  }

  showDropdown() {
    if (this.multi == 'contact') {
      this.contactData = this.list
    }
    if (this.multi == 'multi') {
      this.multiListData = this.listData;
    }
    this.showDropDown = !this.showDropDown
  }
}
