import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { AuthService } from '../auth/auth.service';
import { StorageKeys } from '../../share/enums/storage.enum';
import { environment } from '../../../environments/environment';
// @ts-ignore
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.js';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { faSignOut } from "@fortawesome/free-solid-svg-icons/faSignOut";
import { Router } from "@angular/router";
import { RouteConstants } from 'src/app/share/constants/routes.constants';
import { SdexAdminService } from 'src/app/share/services/sdex/sdex-admin.service';
import { DashBoardService } from 'src/app/share/services/dashboard/dashboard.service';
import { userSelfDetails } from  '../../user-storage';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected readonly faSignOut = faSignOut;
  enrollmentType: any;
  verifyLoader: boolean = false;
  userCircleIcon = faUserCircle;
  isSubMenuActive: boolean = false;
  selectedMenuItem: number = 1;
  user: any = {};
  userName: string | undefined;
  userDetailsData: any;
  profilePic: any;
  name: string
  selectedCompany: any;
  bootstrap: any;
  rolesDisabled = false;
  showTooltip: boolean = false;
  hideText: boolean = false;
  buttonText: string = '<';

  constructor(
    private storageService: StorageService,
    public authService: AuthService,
    private userSettingService: UserSettingService,
    private router: Router,
    private sdexAdminService: SdexAdminService,
    private dashBoardService : DashBoardService
  ) { }

  ngOnInit() {
    this.rolesDisabled = environment.rolesDisabled;
    this.getSelfDetails();
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));

    this.userSettingService.getProfilePic().subscribe((res: any) => {
      this.profilePic = res;
    })
    this.userSettingService.getUpdatedUserName().subscribe((res: any) => {
      this.userDetailsData.name = res;
    })
  }

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  toggleSubMenu() {
    this.isSubMenuActive = !this.isSubMenuActive;
  }

  isMenuItemActive(index: number): boolean {
    return this.selectedMenuItem === index;
  }

  getSelfDetails() {
    userSelfDetails(this.authService, this.sdexAdminService, this.storageService,  (selfDetails: any) => {
      if(selfDetails){
        this.userDetailsData = selfDetails;
        this.profilePic = this.userDetailsData?.profileImage;
      }
      this.user = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
      this.selectedCompany = this.user?.company;
    });
  }

  logout() {
    this.verifyLoader = true;
    this.authService.logout().subscribe(res => {
      if (res.data) {
        localStorage.clear();
        this.router.navigate([RouteConstants.Login]);
        this.verifyLoader = false;
      }
    })
  }

  toggleTextVisibility() {
    this.hideText = !this.hideText;
    this.buttonText = this.hideText ? '>' : '<';
    this.showTooltip = !this.showTooltip;
  }

  hideListText() {
    this.hideText = true
    this.buttonText = '>'
    this.showTooltip = true
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
  onCompanyChange(event: any) {
    if (event?.target?.value) {
      this.user = this.storageService.getDataFromLocalStorage(
        StorageKeys.SELF_DETAILS
      );
      this.selectedCompany = event.target.value;
      this.user.company = this.selectedCompany;
      this.storageService.setDataToLocalStorage(
        StorageKeys.PRESENT_COMPANY,
        this.selectedCompany
      );
      this.storageService.setDataToLocalStorage(
        StorageKeys.SELF_DETAILS,
        this.user
      );
      location.reload();
    }
  }
  scrollTo() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  get getEnrollmentType() {
    if (this.userDetailsData) {
      return this.userDetailsData.assignedTo[0].company.enrollmentType;
    }
    return 1;
  }
}
