import { Component } from '@angular/core';
interface Details{
  label:string;
  data:string;
}

@Component({
  selector: 'app-user-global-setting',
  templateUrl: './user-global-setting.component.html',
  styleUrls: ['./user-global-setting.component.scss']
})
export class UserGlobalSettingComponent {

}
