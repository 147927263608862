import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-breadcrum',
  templateUrl: './common-breadcrum.component.html',
  styleUrls: ['./common-breadcrum.component.scss']
})
export class CommonBreadcrumComponent {
  @Input() title        : any;
  @Input() description  : any;

}
