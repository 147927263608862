<div
  class="notificationcardRow"
  [ngClass]="
    messagestatus?.status ? 'notificationcardRow' : 'unread notificationcardRow'
  "
>
  <div class="active">
    <div *ngIf="messagestatus?.status === 'New'">
      <img
        class="act"
        src="../../../../assets/Images/notification/active.svg"
        alt=""
      />
    </div>
  </div>
  <div class="notification__content">
    <div class="notification__detailsContainer">
      <div
        class="notification__message"
        [ngClass]="{ notification__message1: !messagestatus?.status }"
      >
        {{ messagestatus?.notificationType }}
      </div>
      <div class="partision"></div>
      <div class="notification__sentBy">by {{ messagestatus?.user?.name }}</div>
      <div class="partision"></div>
      <div class="notification__time">
        {{ messagestatus?.hoursBefore | number : "1.0-0" }} hours ago
      </div>
    </div>
    <div class="notification__description">
      {{ messagestatus?.message }}
    </div>
  </div>
</div>
