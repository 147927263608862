
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent  {
  @Input()  options             : any[] = [];
  @Input() addUser              : string;
  @Output() SendSelectedOptions : EventEmitter<any> = new EventEmitter<any>
  selectedOptions               : any[] = [];
  isDropdownOpen                = false;
  selectedOption                = 'Select Role';
  selfDetails                   : any;
  
  constructor(private elementRef: ElementRef, 
    private renderer: Renderer2,
    private storageService: StorageService) {
      this.selfDetails = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
     }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }


  toggleOption(e: any, option: any) {
    let index;
    if (e.target.checked) {
      this.selectedOptions.push(option);
    } else {
      if (this.selfDetails?.enrollmentType == 'Admin') {
        index = this.selectedOptions.findIndex((item: any) => item._id === option._id);
      } else {
        index = this.selectedOptions.findIndex((item: any) => item.value === option.value);
      }
      this.selectedOptions.splice(index, 1);
    }
    this.SendSelectedOptions.emit(this.selectedOptions);
    this.selectedOption = this.selectedOptions.length > 0 ? `${this.selectedOptions.length} selected` : 'Select Role';
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    // Check if the clicked element is inside the dropdown or the dropdown label
    const dropdownElement = this.elementRef.nativeElement.querySelector('.custom-dropdown');
    if (!dropdownElement.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

}
