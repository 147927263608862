import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-org-details-second-step',
  templateUrl: './org-details-second-step.component.html',
  styleUrls: ['./org-details-second-step.component.scss'],
})
export class OrgDetailsSecondStepComponent {
  @Output() closePanelTwo: EventEmitter<void> = new EventEmitter<void>();

  onCloseIconClick() {
    this.closePanelTwo.emit();
  }
  
}
