import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Input() title          : string;
  @Output() closeToaster  : EventEmitter<any> = new EventEmitter<any>();
  delayMilliseconds       : number;
  showsToast              : boolean = true;

  constructor() {
    this.startCloseTimer();
  }

  closeToast() {
    this.showsToast = false;
    this.closeToaster.emit(false);
  }

  //Setting the timer for automatically closing the toaster
  startCloseTimer() {
    const delayMilliseconds = 1500;
    setTimeout(() => {
      this.showsToast = false;
      this.closeToaster.emit(false);
    }, delayMilliseconds);
  }
  
}
