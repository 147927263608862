<div class="createPackage__header" >
    <div class="createPackage__heading">{{createPackageHeader || 'Create Package'}}</div>
    <ng-container *ngIf="showButton">
      <div class="createPackage__saveDraft cursor-pointer" (click)="sendToDrafts()" *ngIf="((draftStatus !== 'Draft') && (draftStatus !== 'Rejected') && (draftStatus !=='Requested')) && selectionMode !=  'RETURN'">
          <div *ngIf="buttonClicked" class="createPackage__saveDraftIcon"><img src="../../../../assets/Images/create-package/draftSaved.svg" alt="draftSaved"></div>
          <div *ngIf="loadingIcon">
            <app-common-icon [faIcon]="faSpinner"
            [loadingIcon]="loadingIcon"></app-common-icon>
          </div>
          <div class="createPackage__saveDraftText">Save As Draft</div>
      </div>
    </ng-container>
  <div class="createPackage__saveDraft cursor-pointer" (click)="sendToDrafts()" *ngIf="draftStatus === 'Draft' && selectionMode !=  'RETURN' && !isChecker">
    <div *ngIf="buttonClicked" class="createPackage__saveDraftIcon"><img src="../../../../assets/Images/create-package/draftSaved.svg" alt="draftSaved"></div>
    <div *ngIf="loadingIcon">
      <app-common-icon [faIcon]="faSpinner"
      [loadingIcon]="loadingIcon"></app-common-icon>
    </div>
    <div class="createPackage__saveDraftText">Update Draft</div>
  </div>

</div>
