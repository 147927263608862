import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptRejectConfirmationModalComponent } from '../../../admin/company/accept-reject-confirmation-modal/accept-reject-confirmation-modal.component';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent {

  @Input() userData     : any;
  @Output() userEvent   = new EventEmitter<any>();
  maxFilelength         : number  = 25;
  isAdmin               : boolean = false;
  userRoleData          : any     = [];
  companyData           : any     = [];

  @ViewChild('Branch') branchpOverlayPanel           : OverlayPanel;
  @ViewChild('Company') companypOverlayPanel         : OverlayPanel;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userData'].currentValue) {
      const selfDetails =
        this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
      if (selfDetails.enrollmentType === "Admin") {
        this.isAdmin = true;
      }
      this.getRolesAndBranches();
    }
  }

  constructor(private modalService: NgbModal,
    private storageService: StorageService
    ) { }

  getRolesAndBranches() {
    for (let i = 0; i < (this.userData || []).length; i++) {
      this.userData[i].branches = [];
      this.userData[i].branchRoles = [];
      this.userData[i].assignedTo?.map((item: any) => {
        if (item.company?.name) {
          this.userData[i].branches.push(item.company?.name);
        }
        if (item.isAdmin) {
          this.userData[i].branchRoles.push('Admin');
        } else {
          item?.roles?.map((y: any) => {
            if (y?.name) {
              this.userData[i].branchRoles.push(y.name);
            }
          });
        }
      });
      this.userData[i].company = this.userData[i].branches.join(', ');
      this.userData[i].roles = this.userData[i].branchRoles.join(', ');
    }
  }

  editUser(value: any, user: any) {
    this.userEvent.emit({ event: value, user });
  }

  /*added fa icons */
  protected readonly faEye = faEye;
  protected readonly faBan = faBan;
  protected readonly faLock = faLock;

  /* to activate company */
  activateCompany(userData: any, isActive: any) {
    let dialogRef = this.modalService.open(
      AcceptRejectConfirmationModalComponent,
      {
        backdrop: 'static',
        size: 'md',
        keyboard: false,
        centered: true,
      }
    );
    if(dialogRef){
    dialogRef.componentInstance.companyActivated = isActive;
    dialogRef.componentInstance.message = `Do You Want To ${isActive} "${userData?.name}" ? `
    dialogRef.result.then(
      (result) => {
        if (result?.status) {
          if (isActive == 'Activate') {
            this.userData
              .activateCompany(userData._id, 'activate');
          } else if (isActive === 'Deactivate') {
            this.userData.deleteRootCompany(userData._id).subscribe((res: any) => {

            });
          }
        }
      }
    );
  }
}
  largeFileName(fileName: string) {
    return fileName.substring(0, this.maxFilelength);
  }
  getTooltipEamilName(email: any) {
    return email.length >= this.maxFilelength ? email : '';
  }
  getTooltipName(name: any) {
    return name.length >= 10 ? name : '';
  }

  openPanel(type: string, tableData: any, event: any) {
    if (type === "Branch") {
      if (tableData.roles.length >= 21) {
        this.branchpOverlayPanel?.show(event);
        if (tableData.roles.length > 0) {
          this.userRoleData = tableData.roles.split(",");
        }
      }
    } else if (type === "Company") {
      if (tableData.company.length >= 21) {
        this.companypOverlayPanel?.show(event);
        if (tableData.company.length > 0) {
          this.companyData = tableData.company.split(",");
        }
      }
    }

  }

  closePanael(type: string) {
    if (type === "Branch") {
      this.branchpOverlayPanel?.hide();
    } else {
      this.companypOverlayPanel?.hide();
    }
  }

}
