<section class="userGlobalSetting__container">
    <app-side-nav-bar></app-side-nav-bar>
    <div class="userGlobalSetting__mainBody">
        <app-user-setting-header></app-user-setting-header>
        <app-organisational-details showData="user" marginClass="userView" userViewImage="imageMargin" ></app-organisational-details>
        <div>
            <app-notifcation-settings userClass="userNotification" userNotifyCheckbox="checkboxClass" ></app-notifcation-settings>
        </div>
    
    </div>
    
</section>