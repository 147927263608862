import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notifcation-settings',
  templateUrl: './notifcation-settings.component.html',
  styleUrls: ['./notifcation-settings.component.scss']
})
export class NotifcationSettingsComponent {

  @Input() userClass: string
  @Input() userNotifyCheckbox: string;
}
