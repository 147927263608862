
<div class="notificationCard__container">
    <div class="notificationCard__dateContainer">{{dailyData?.date}}
        <div class="notificationcard__arrow"><img src="../../../../assets/Images/notification/dateArrow.svg" alt="iconarrow"></div>
    </div>
    <div class="notificationCard__cardwrapper">
        <ng-container *ngFor="let messagestatus of dailyData?.messageinfo;let last = last">
            <div  [ngClass]="last ? 'no-border' : 'notificationCard__card'">
                <app-notification-card-row [messagestatus]="messagestatus"></app-notification-card-row>
            </div>

        </ng-container>
    </div>

</div>
