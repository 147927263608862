<div style="z-index: 10000 !important;">
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <div (click)="closeDialog()">
      <img _ngcontent-dbj-c266="" src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon">
    </div>
  </div>
  <div class="modal-body" *ngIf="!companyActivated && !roleId && !companyActivatedData && !userId">
    Are you sure you want to perform this action ?
  </div>
  <div class="modal-body" *ngIf="companyActivated || userId || roleId  || companyActivatedData">
    {{message}}
  </div>

  <div *ngIf="errorMsg" class="text-danger text-center">
    {{ errorMsg }}
  </div>
  <div class="modal-footer">
    <app-common-button buttonClass="smallbutton" [buttonLabel]="companyActivatedData ? 'ok' : 'Cancel'"
      (click)="closeDialog()">
    </app-common-button>
    <app-common-button *ngIf="acceptRejectData" buttonClass="smallFilledButton"
      buttonLabel="{{ acceptRejectData === 'Accept' ? 'Accept' : 'Reject' }}"
      (clickEvent)="confirmation(acceptRejectData)">
    </app-common-button>
    <app-common-button *ngIf="companyActivated" buttonClass="smallFilledButton" [buttonLabel]="companyActivated"
      (clickEvent)="confirmation('isActivated')">
    </app-common-button>
    <!-- roledeletion -->
    <app-common-button buttonClass="smallFilledButton" buttonLabel="Delete" *ngIf="roleId && !roleErrorMsg" (click)="deleteRole()"
      appAccessControl allowedRoles="role.delete">
    </app-common-button>
    <app-common-button *ngIf="roleErrorMsg" buttonClass="smallFilledButton" buttonLabel="Ok" (click)="closePopup()">
    </app-common-button>
    <app-common-button buttonClass="smallFilledButton" buttonLabel="Lock" *ngIf="userId" (click)="deleteUser()"
      appAccessControl allowedRoles="user.disable">
    </app-common-button>
  </div>
</div>