import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-page-header',
  templateUrl: './list-page-header.component.html',
  styleUrls: ['./list-page-header.component.scss']
})

export class ListPageHeaderComponent {
  @Input() headerTitle        : string;
  @Input() headerDescription  : string;
}
