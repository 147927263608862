<section class="globalSettingPreview__container">
    <app-side-nav-bar></app-side-nav-bar>
    <div class="globalSettingPreview__mainBody">
        <app-global-setting-header></app-global-setting-header>
        <app-organisational-details></app-organisational-details>
        <div class="globalSettingPreview__verification">
            <app-info-cards *ngFor="let data of verifiedData" [verified]="data"></app-info-cards>
        </div>
        <div>
            <app-notifcation-settings></app-notifcation-settings>
        </div>
    </div>
</section>