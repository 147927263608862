import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptRejectConfirmationModalComponent } from 'src/app/admin/company/accept-reject-confirmation-modal/accept-reject-confirmation-modal.component';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})

export class UserDetailsComponent implements OnInit {
  @Input() companyId        : any;
  @Input() parentData       : any;
  @Input() permissionData   : any; 
  @Output() closePanel      : EventEmitter<void> = new EventEmitter<void>();
  @Output() editUser        : EventEmitter<any> = new EventEmitter<any>();
  @Output() userDeleted     : EventEmitter<any> = new EventEmitter<any>();
  userId                    : string;
  selfDetails               : any;
  isAdmin                   : boolean = false;
  isEsignApplicable         : boolean = true;
  isBlockChainApplicable    : boolean = true;


  constructor(
    private modal: NgbModal,
    private storageService: StorageService,private userSettingService:UserSettingService) { }

  onCloseIconClick() {
    this.closePanel.emit();
    document.body.classList.remove('panel-open');
  }

  ngOnInit(): void {
    this.selfDetails = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (this.selfDetails) {
      this.userId = this.selfDetails._id
    }
    if (this.selfDetails?.enrollmentType === "Admin") {
      this.isAdmin = true;
    }
    this.getBlockChainKey();
  }

  deleteUser(id: string) {
    let dialogRef = this.modal.open(AcceptRejectConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
    dialogRef.componentInstance.userId = this.parentData?._id;
    dialogRef.componentInstance.message = `Do you want to lock this user '${this.parentData.name ? this.parentData.name:""}' ?`;
    dialogRef.result.then(
      (result) => {
        if (result?.status) {
          this.closePanel.emit();
          this.userDeleted.emit()
          document.body.classList.remove('panel-open');

        }

      },
      (reason) => {

      }
    );
  }

  handleEditUser() {
    this.editUser.emit(this.parentData);
  }

  getBlockChainKey() {
    this.userSettingService.getblockchainkey(this.parentData?._id)?.subscribe(response => {
      this.parentData.isKeyGenerated = response.data.keyGenerated;
    });
  }

}
