import { Component, Input,ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
@Component({
  selector: 'app-packages-table-card',
  templateUrl: './packages-table-card.component.html',
  styleUrls: ['./packages-table-card.component.scss']
})
export class PackagesTableCardComponent {

  @Input() packagesTableData:any;
  @ViewChild('op') overlayPanel: OverlayPanel;

}

