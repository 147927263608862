<div class="modal-header">
  <h4 class="modal-title">Logout</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="closeDialog()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">Are You Sure You want to logout?</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeDialog()">
    Cancel
  </button>
  <button type="button" class="btn btn-danger" (click)="logout()">Yes</button>
</div>
