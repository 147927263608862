<div class="modal-header">
  <h4 class="modal-title">Delete Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">Are you sure you want to delete this item?</div>
<div *ngIf="errorMsg" class="text-danger text-center">
  {{ errorMsg }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeDialog()">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="deleteUser()">Delete</button>
</div>