import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'app-packages-type-card',
  templateUrl: './packages-type-card.component.html',
  styleUrls: ['./packages-type-card.component.scss']
})
export class PackagesTypeCardComponent {

  isActive: boolean;

  @Input() packageData:any;
  @Output() selectedPackage:any

}
