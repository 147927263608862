import { Component,EventEmitter,Input, Output } from '@angular/core';

@Component({
  selector: 'app-offcanvas-header',
  templateUrl: './offcanvas-header.component.html',
  styleUrls: ['./offcanvas-header.component.scss']
})
export class OffcanvasHeaderComponent {
  @Input() header:string;
  @Input() ExtraIconClass:string;
  @Input() iconSrc:string;
  @Output() closeClicked = new EventEmitter<void>();
  @Output() clearFilters = new EventEmitter<void>();


  @Input() paddingClass:string;
  // for closing the offcanvas on clicking close button


  closeOffcanvas(){
    this.closeClicked.emit();
  }
  clear_Filter(){
    this.clearFilters.emit();

  }
  


}
