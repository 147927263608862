<div class="breadcrumPage__main">
    <div class="breadcrumPage__main-section">
        <div class="breadcrumPage__main-management-section">
            <div class="breadcrumPage__breadcrumHead">
                <div class="breadcrumPage__breadcrumTitle">{{title}}</div>
                <div class="breadcrumPage__breadcrumDescription">
                    {{description}}
                </div>
            </div>
        </div>
    </div>
</div>