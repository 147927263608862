import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-screen',
  templateUrl: './notification-screen.component.html',
  styleUrls: ['./notification-screen.component.scss']
})
export class NotificationScreenComponent {
  NotificationData=[{
    "date": "Today , 18 Feb 2023",
    "messageinfo": [{
        "message": "New Invite Request",
        "sender": "by Amitesh Sharma",
        "timeago": "2 hours ago",
        "company": " Tata Steel Pvt. Ltd.",
        "status": true
  }, {
        "message": "New Package Received",
        "sender": "by Samantha Adams",
        "timeago": "5 hours ago",
        "company": " Tata Steel Pvt. Ltd.",
        "status": true
      }]
  }, {
    "date": "Yesterday , 17 Feb 2023",
    "messageinfo": [{
        "message": "Package Queried",
        "sender": "by Amitesh Sharma",
        "timeago": "2 hours ago",
        "company": " Tata Steel Pvt. Ltd.",
        "status": true
      }, {
        "message": "Package Draft Reminder",
        "sender": "by Amitesh Sharma",
        "timeago": "2 hours ago",
        "company": " Tata Steel Pvt. Ltd.",
        "status": false
      }]
  }];

}
