<form [formGroup]="filterForm" class="form-inline">
  <div class="input_align">
    <div class="form-group">
      <label for="startDate" class="mr-2">Date From</label>
      <input type="date" class="form-control" id="startDate" formControlName="startdate">
    </div>

    <div class="form-group">
      <label for="endDate" class="mr-2">Date To</label>
      <input type="date" class="form-control" id="endDate" formControlName="enddate">
    </div>

    <div class="form-group ml-3">
      <label for="documentType">Select document type</label>
      <select class="form-control" id="documentType" formControlName="documentType">
        <option *ngFor="let document of documentTypes" [value]="document.name">{{ document.name }}</option>
      </select>
    </div>

    <div class="form-group ml-3">
      <label for="searchInput">Search files</label>
      <div class="input-group">
        <input type="text" class="form-control" id="searchInput" formControlName="documentName" placeholder="Search">
        <div class="input-group-append">
          <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
        </div>
      </div>
    </div>
    <div class="form-group m-2">
      <label for="selectOption">Recived From:</label>
      <select class="form-control" id="selectOption" formControlName="selectedContact">
        <option value="Select" selected>Select</option>
        <option *ngFor="let contact of contactList" [value]="contact.name">
          {{ contact.name }}
        </option>
      </select>
    </div>
  </div>
  <button *ngIf="formValues" (click)="resetForm()" class="btn btn-primary ml-3">Reset filters</button>
</form>