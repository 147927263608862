import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bulk-upload-users',
  templateUrl: './bulk-upload-users.component.html',
  styleUrls: ['./bulk-upload-users.component.scss']
})
export class BulkUploadUsersComponent {
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  onCloseIconClick() {
    this.closePanel.emit();
  }
}
