import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { AuthService } from '../auth/auth.service';
import * as CryptoJS from 'crypto-js';
import { userSelfDetails } from 'src/app/user-storage';
import { SdexAdminService } from 'src/app/share/services/sdex/sdex-admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  loginForm     !: FormGroup;
  hide           = true;
  signInLoader   : boolean = false;
  typeBtn        : boolean;
  errorMessage  !: string;
  companyDetails : any;
  errorLogin     : any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private storageService: StorageService,
    private sdexAdminService: SdexAdminService,
    private router: Router
  ) {
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        [Validators.required, Validators.pattern(RegExpPatterns.EmailPattern)],
      ],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.getSelfDetails();
    }
  }

  onLogin(): void {
    if(this.signInLoader){
      return;
    }
    this.signInLoader = true;
    const credentials = {
      username: this.loginForm.value.username,
      password: CryptoJS.SHA256(this.loginForm.value.password).toString(CryptoJS.enc.Hex),
    }

    this.authService.login(credentials).subscribe({
      next: (response: any) => {
        this.storageService.setDataToLocalStorage(
          StorageKeys.ACCESS_TOKEN,
          response.data.token
        );
        this.storageService.setDataToLocalStorage(
          StorageKeys.COMPANY_ID,
          response.data.company
        );
        this.getSelfDetails();
        this.signInLoader = false;
      },

      error: (error) => {
        this.errorLogin = error?.error?.error?.message;
        this.signInLoader = false;
      }
    });
  }

  getSelfDetails() {

    this.authService.getSelfDetails().subscribe({
      next: (res) => {
        if (res.data.status === 'Active') {
          userSelfDetails(this.authService, this.sdexAdminService, this.storageService, (selfDetails: any) => {
            if (selfDetails) {
              location.reload();
            }
          });
          if (res.data.enrollmentType == 'Admin') {
            this.router.navigate(['/admin/sdex/company']);
          } else {
            this.router.navigate(['/admin/dashboard']);
          }
        }
        else if (res.data.status === 'Locked') {
          this.errorLogin = 'User has been locked';
        } else {
          this.errorLogin = 'Company not activated';
        }
      },
      error: (error) => {
        this.errorLogin = error?.error?.error?.message;
      }
    });
  }

  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;

}
