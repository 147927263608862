<section class="notificationSettings__container" [ngClass]="userClass">
    <div class="notificationSettings__heading">
        Notification Settings
    </div>
    <div class="notificationSettings__main">
        <div class="notificationSettings__notificationHead">
            Alert & Reminder
        </div>
        <div class="notificationSettings__notificationMessege">
            Includes all the alerts related to all the packages, networks and other modules
        </div>
    </div>
    <div class="notificationSettings__checkboxes" [ngClass]="userNotifyCheckbox">
        <app-checkbox className="labelWidth" checkBoxName="Email"></app-checkbox>
        <app-checkbox className="labelWidth" checkBoxName="In-App"></app-checkbox>
    </div>
</section>