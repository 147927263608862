
<div class="overlay">
    <div class="popup">
        <div class="blockchaintransfer__card">
            <div class="blockchaintransfer__header">
                <div class="blockchaintransfer__heading">
                  {{!blockchainTransfer ? "Package Transfer" : "Blockchain Transfer"}}
                </div>
                <div class="blockchaintransfer__cancelIcon"><img (click)="closePopup()" src="../../../../assets/Images/roles-responsibilities/cancel.svg" alt="cancelicon"></div>
            </div>
            <div class="blockchaintransfer__content">
                <div class="blockchaintransfer__loadingIcon"><img [src]="successProcessing" alt="statusProcessing"></div>
                <div class="blockchaintransfer__loadingContent" *ngIf="blockchainTransfer">Executing Blockchain Transfer</div>
                <div class="blockchaintransfer__loadingContentStatus">Please wait while your transfer is executed. </div>
            </div>
            <div class="blockchaintransfer__transferStatus">
                <div class="blockchaintransfer__eachstatus">
                    <div class="blockchaintransfer__verified"><img src="../../../../assets/Images/create-package/success-step.svg" alt="added"></div>
                    <div class="blockchaintransfer__statustext">Uploaded documents to secure storage</div>
                </div>
                <div class="blockchaintransfer__eachstatus">
                    <div class="blockchaintransfer__inprogress" *ngIf="processOne === 'Waiting'">
                      <img src="../../../../assets/Images/create-package/Loading.svg" alt="loading">
                    </div>
                    <div class="blockchaintransfer__verified" *ngIf="processOne === 'Sent'">
                      <img src="../../../../assets/Images/create-package/success-step.svg" alt="added">
                    </div>
                    <div class="blockchaintransfer__statustext">{{processOne === 'Waiting'? 'Signing documents in the package':'Signed documents in the package'}} </div>
                </div>
                <div class="blockchaintransfer__eachstatus">
                    <div class="blockchaintransfer__inprogress" *ngIf="processTwo === 'Waiting'"><img src="../../../../assets/Images/create-package/Loading.svg" alt="loading"></div>
                    <div class="blockchaintransfer__verified" *ngIf="processTwo === 'Sent'"><img src="../../../../assets/Images/create-package/success-step.svg" alt="added"></div>
                    <div class="blockchaintransfer__statustext">
                    {{blockchainTransfer? processTwo==='Waiting'?'Sending transactions to blockchain':'Sent transactions to blockchain':processTwo==='Waiting'?'Sending documents in the package':'Sent documents in to the package'}}
                    </div>
                </div>
            </div>
        </div>
      
    </div>
  </div>
