import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/home/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigGuard  {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(): boolean {
    return true;

  }
}
