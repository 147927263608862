import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-org-details-card',
  templateUrl: './org-details-card.component.html',
  styleUrls: ['./org-details-card.component.scss'],
})
export class OrgDetailsCardComponent {
  @Input() title      : string;
  @Input() description: string;
}
