import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

})
export class AppComponent {
  constructor(private router: Router) {
    let definedRouter = this.router;
    this.router.events.subscribe(function (event) {

    });

    function LogoutApplication(){
      localStorage.clear();
      definedRouter.navigate(['login']);
    }

    const bc = new BroadcastChannel("sdex-web-app");
    bc.onmessage = (event) => {
      if(event.data === 'logOut'){
        LogoutApplication();
      }else if (event.data !== window.sessionStorage["tabId"]) {
         localStorage.setItem("loadingNewWebsite", (new Date()).getTime().toString());
      }
    };

     window.addEventListener("load", function (e)
    {
      if (!window.sessionStorage["tabId"] && router.url !== "/setup-account" && router.url !== "/forgot-password"){
        window.sessionStorage["tabId"] = Date.now();
        bc.postMessage(window.sessionStorage["tabId"].toString());
        setTimeout(function (){
          let modifiedTime = localStorage.getItem("loadingNewWebsite");
          if((!modifiedTime) || (Math.floor((((new Date()).getTime()) - Number(modifiedTime)) / 1000)) > 2 ){
            bc.postMessage("logOut");
            LogoutApplication();
          }
        }, 2000);
      }
      return null;
    });
  }
}
