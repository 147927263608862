<tr class="packageTable__rowMain">
  <td class="packageTable__networkName">
    <div class="packageTable__networkimage">
      <img [src]="packagesTableData?.organisationImgSrc" alt="networkImage" />
    </div>
    <div class="packageTable__line"></div>
    <div class="packageTable__networkDetails">
      <div class="packageTable__name">
        {{ packagesTableData?.organisationName }}
      </div>
      <div class="packageTable__location">
        {{ packagesTableData?.organisationLocation }}
      </div>
    </div>
  </td>
  <td class="packageTable__packageID">
    <div class="packageTable__packageIDstatusImage">
      <div
        *ngIf="packagesTableData?.packageImgStatus === 'Sent'"
        class="packageTable__packageIDstatusImg"
      >
        <img
          src="../../../../assets/Images/package-listing/id-Sent.svg"
          alt="sent"
        />
      </div>
      <div
        *ngIf="packagesTableData?.packageImgStatus === 'Received'"
        class="packageTable__packageIDstatusImg"
      >
        <img
          src="../../../../assets/Images/package-listing/id-Recieved.svg"
          alt="recieved"
        />
      </div>
      <div
        *ngIf="packagesTableData?.packageImgStatus === 'Drafted'"
        class="packageTable__packageIDstatusImg"
      >
        <img
          src="../../../../assets/Images/package-listing/id-Draft.svg"
          alt="drafted"
        />
      </div>
      <div
        *ngIf="packagesTableData?.packageImgStatus === 'Queried'"
        class="packageTable__packageIDstatusImg"
      >
        <img
          src="../../../../assets/Images/package-listing/id-Queried.svg"
          alt="queried"
        />
      </div>
      <div
        *ngIf="packagesTableData?.packageImgStatus === 'Closed'"
        class="packageTable__packageIDstatusImg"
      >
        <img
          src="../../../../assets/Images/package-listing/id-Sent.svg"
          alt="closed"
        />
      </div>
    </div>
    <div class="packageTable__packageIDValue">
      <div class="packageTable__packageIDcode">
        {{ packagesTableData?.packageIDcode }}
      </div>
      <div
        *ngIf="packagesTableData?.packageIDStatus != ''"
        class="packageTable__packageIDStatus"
      >
        {{ packagesTableData?.packageIDStatus }}
      </div>
    </div>
  </td>
  <td class="packageTable__lastUpdated">
    {{ packagesTableData?.packageLastUpdated }}
  </td>
  <td class="packageTable__documents">
    <div class="packageTable__documentDetails">
      {{ packagesTableData?.ownershipDocCount }}
      <div (click)="op.toggle($event)" class="packageTable__ownership">
        Ownership
      </div>
      <p-overlayPanel #op>
        <ng-template pTemplate="content">
          <div class="hoverComponent__body">
            <div class="hoverComponent__bodySection">
              <div class="hoverComponent__details">
                <div class="hoverComponent__bullets"></div>
                <div class="hoverComponent__detailsText">Air waybill</div>
              </div>
              <div class="hoverComponent__details">
                <div class="hoverComponent__bullets"></div>
                <div class="hoverComponent__detailsText">Bill of Landing</div>
              </div>
              <div class="hoverComponent__detailsWrapper">
                <div class="hoverComponent__bullets"></div>
                <div class="hoverComponent__detailsText">
                  Electronic Negotiable Warehouse Receipt (WRDA Certified)
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>
    <div
      *ngIf="packagesTableData?.authenticationDocCount != ''"
      class="packageDocBorder"
    ></div>
    <div
      *ngIf="packagesTableData?.authenticationDocCount != ''"
      class="packageTable__documentDetails"
    >
      {{ packagesTableData?.authenticationDocCount }}
      <div class="packageTable__authentication">Authentication</div>
    </div>
    <div
      *ngIf="packagesTableData?.supportDocCount != ''"
      class="packageDocBorder"
    ></div>
    <div
      *ngIf="packagesTableData?.supportDocCount != ''"
      class="packageTable__documentDetails"
    >
      {{ packagesTableData?.supportDocCount }}
      <div class="packageTable__support">Support</div>
    </div>
  </td>
</tr>
